import {reQuest} from "@/request/axios";


/****
 * Api:查询火点列表（无分页）
 * @param params ：{
 *     reliabilityList：array[string] 可靠性
 *     areaId ：string                行政区ID
 *     seekOutTimeStart：number 毫秒值 开始时间
 *     seekOutTimeEnd：number         结束时间
 * }
 * @param sourceType: 8 默认调用保存的葵花卫星公共数据
 */

export const GetFirePoint = (params) => {
    return reQuest({
        url: '/blade-overview/selectFirePointByGeoJson2',
        method: 'get',
        params: {
            ...params,
            sourceType: 8
        }
    })
}

/****
 * Api:查询火点列表分页
 * @param params ：{
 *     reliabilityList：array[string] 可靠性
 *     areaId ：string                行政区ID
 *     seekOutTimeStart：number 毫秒值 开始时间
 *     seekOutTimeEnd：number         结束时间
 * }
 * @param size         分页查询每条数据量
 * @param page         分页查询当前页
 * @param sourceType8 默认调用保存的葵花卫星公共数据
 */

export const GetFirePointPaginated = (params, size, page) => {
    return reQuest({
        url: '/blade-overview/selectFirePointByGeoJson',
        method: 'get',
        params: {
            ...params,
            sourceType: 8,
            size: size,
            current: page,
        }
    })
}
/***
 * Api:查询目标行政区划下的子集以及ID
 * @param code  目标行政区划
 */
export const GetAreaIdData = (code) => {
    return reQuest({
        url: '/blade-system/region/select',
        method: 'get',
        params: {
            code: code
        }
    })
}