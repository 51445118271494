import Vue from 'vue'
import VueRouter from 'vue-router'
import starVisionMap from "@/components/starVisionMap.vue";
import HistoricalFirePoint from "@/components/HistoricalFirePoint.vue";

Vue.use(VueRouter)
const routes = [
    {
        path:'/',
        name:'starVisionMap',
        component: starVisionMap,
        meta:{
           name: '首页'
        }
    },{
        path:'/HistoricalFirePoint',
        name:'HistoricalFirePoint',
        component: HistoricalFirePoint,
        meta:{
            name: '历史火点'
        }
    },
    {
        path:'/newMap',
        name:'newMap',
        component: ()=>import('@/page/newMap.vue'),
        meta:{
            name: '历史火点地图'
        }
    }
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
export default router