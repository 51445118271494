import mapBoxGL from "mapbox-gl";

// 更具级别进行分级
const mag1 = ['<', 1, 1];
const mag2 = ['all', ['>=', ['get', 'id'], 2], ['<', ['get', 'id'], 1]];
const mag3 = ['all', ['>=', ['get', 'id'], 3], ['<', ['get', 'id'], 1]];
const mag4 = ['all', ['>=', ['get', 'id'], 4], ['<', ['get', 'id'], 1]];
const mag5 = ['>=', ['get', 'id'], 1];

let flag = true
export const changeFlag = (a) =>{
    flag = a
}
export const  addFirePoint = (map, data, dataName,markers,markersOnScreen) => {
    if (map.getSource(dataName)) {
        if (map.getLayer(dataName + '_layer')) map.removeLayer(dataName + '_layer');
        map.removeSource(dataName)
    }
// 设置各个类别的颜色
    map.addSource(dataName, {
        'type': 'geojson',
        'data': data,//火点经纬度数据
        'cluster': true,
        'clusterRadius': 30,//30
        'clusterProperties': {
// 对集群中的每个类别进行单独计数
            'mag1': ['+', ['case', mag1, 1, 0]],
            'mag2': ['+', ['case', mag2, 1, 0]],
            'mag3': ['+', ['case', mag3, 1, 0]],
            'mag4': ['+', ['case', mag4, 1, 0]],
            'mag5': ['+', ['case', mag5, 1, 0]]
        }
    });
    if (!map.hasImage('build')) {
        map.loadImage('https://forest-fire.oss-cn-hangzhou.aliyuncs.com/image/fire.png',//添加图片的地址
            function (error, image) {
                if (error) throw error;

                map.addImage('build', image);
            });
    }
    map.addLayer({
        'id': dataName + '_layer',
        'type': 'symbol',
        'source': dataName,
        'filter': ['!=', 'cluster', true],
        'layout': {
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-size': 10,
            'icon-image': 'build',
            'icon-size': 0.1
        }
    });
    map.on('click', dataName + '_layer', (e) => {
        let props = e.features[0].properties
        new mapBoxGL.Popup({ closeOnClick: true })
            .setLngLat(e.lngLat)
            .setHTML(`<h4>火点位置：${props.firePointName?props.firePointName:'——'}</h4>
                       <h4>经纬度：(${props.longitude},${props.latitude})</h4>
                       <h4>置信度：${props.reliability}</h4>
                       <h4>观测时间：${props.seekOutTime}</h4>
                                            `)
            .addTo(map);
    })
    // 对象，用于缓存和跟踪HTML标记对象（用于性能）
    // const markers = {};
    // let markersOnScreen = {};

    function updateMarkers() {  //更新聚合markers方法
        const newMarkers = {};
        const features = map.querySourceFeatures(dataName);

//为屏幕上的每个集群创建一个HTML标记（如果我们还没有）
//如果地图上还没有，就把它添加到地图上
        for (const feature of features) {
            const coords = feature.geometry.coordinates;
            const props = feature.properties;
            if (!props.cluster) continue; //判断json特征属性的聚合属性是否被聚合。如果没被聚合则跳过此循环
            const id = props.cluster_id;//被聚合后则记录该点位的聚合id

            let marker = markers[id];//被聚合为同一聚合点的点  cluster_id是相同的
            if (!marker) {
                const el = createDonutChart(props);
                el.className = dataName;
                el.style.userSelect = 'none';
                el.addEventListener("click", () => {
                    new mapBoxGL.Popup({ closeOnClick: false })
                        .setLngLat(coords)
                        .setHTML(`<h2>火点总数：${props.point_count}</h2>`)
                        .addTo(map);
                });
                marker = markers[id] = new mapBoxGL.Marker({    //已聚合id为key marker实例为内容填充入markers。后续遍历到同个聚合ID就不会执行
                    element: el
                }).setLngLat(coords);
            }
            newMarkers[id] = marker; //对newMarkers变量执行同上操作

            if (!markersOnScreen[id]) marker.addTo(map);//如果markerOnScreen变量记录上没有则渲染到map上
        }
//对于我们之前添加的每个标记，删除那些不再可见的标记
        for (const id in markersOnScreen) {
            if (!newMarkers[id]) markersOnScreen[id].remove();
        }
        markersOnScreen = newMarkers;
    }

    function a() {
        if (!map.isSourceLoaded(dataName)) return;
        updateMarkers();
    }

//加载GeoJSON数据后，在每一帧上更新屏幕上的标记
    if (flag) {
        map.on('render', a);
        flag = !flag
    }


}
export const upDateLayer = (map,sourceName,res)=>{
    if (map.getSource(sourceName)){
        if (map.getLayer(sourceName+'-fills')){
            map.removeLayer(sourceName+'-borders');
            map.removeLayer(sourceName+'-fills');
            map.removeSource(sourceName)
        }
    }
    map.addSource(sourceName, {
        'type': 'geojson',
        'data': res
    });
    map.addLayer({
        'id': sourceName+'-fills',
        'type': 'fill',
        'source': sourceName,
        'layout': {},
        'paint': {
            'fill-color': '#627BC1',
            'fill-opacity': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                1,
                0
            ]
        }
    });
    map.addLayer({
        'id': sourceName+'-borders',
        'type': 'line',
        'source': sourceName,
        'layout': {},
        'paint': {
            'line-color': '#627BC1',
            'line-width': 2
        }
    });
}
function createDonutChart(props) {
    const counts = [
        props.mag1,
        props.mag2,
        props.mag3,
        props.mag4,
        props.mag5
    ];
    let total = 0;
    for (const count of counts) {
        total += count;
    }
    const fontSize =
        total >= 1000 ? 21 : total >= 100 ? 19 : total >= 10 ? 17 : 15;
    const r =
        total >= 1000 ? 50 : total >= 100 ? 32 : total >= 10 ? 24 : 18;
    const r0 = Math.round(r * 0.6);
    const w = r * 2;
    if (Math.random() > 0.5) {
        var html = `<div class="fire" style="background-image: url('https://forest-fire.oss-cn-hangzhou.aliyuncs.com/image/fire_ani1.apng');background-size: ${w}px;">
<svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block; color: white">`;
    } else {
        html = `<div class="fire" style="background-image: url('https://forest-fire.oss-cn-hangzhou.aliyuncs.com/image/fire_ani2.apng');background-size: ${w}px;">
<svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block; color: white">`;
    }

    html += `<circle cx="${r}" cy="${r}" r="${r0}"  fill="rgba(0,0,0,0)" style="color: white"/>
<text dominant-baseline="central" transform="translate(${r}, ${r})" id="text" fill="white">
${total}
</text>
</svg>
</div>`;
    const el = document.createElement('div');
    el.innerHTML = html;
    return el.firstChild;
}
