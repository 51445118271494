import Vue from 'vue'
import App from './App.vue'
import axios  from "axios";
import router from "@/router";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI,router)
Vue.config.productionTip = false
Vue.prototype.$axios =axios
axios.defaults.baseURL='/api'
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
