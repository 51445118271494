<template>
<div class="background">
  <img src="https://forest-fire.oss-cn-hangzhou.aliyuncs.com/image/HFPbackground.png" alt="" srcset="">
  <div class="main">
    <div class="RangeSelection">
      <div class="Option" @click="chinaRange"  :class="{Selected: chinaRangeActive}">中国境内</div>
<!--      <div class="Option" :class="{Selected: worldRangeActive}" style="text-decoration-line: line-through">世界范围</div>-->
    </div>
    <div class="spacetimeSelection">
      <div class="Option"  @click="ConditionalOptions('time')"  :class="{Selected: timeRangeActive}">按时间</div>
      <div class="Option"  @click="ConditionalOptions('area')"  :class="{Selected: areaRangeActive}">按区域</div>
    </div>
    <div class="timeSelectInput">
      <el-date-picker
          style="flex: 2; margin-right: 20px;"
          v-model="selectedTime"
          type="datetimerange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['12:00:00']"
          value-format="timestamp"
      >
      </el-date-picker>
      <el-select
          style="flex: 2; margin-right: 20px;" v-model="value1" multiple collapse-tags placeholder="请选择置信度" >
        <el-option
            v-for="item in reliabilityLists"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            :popper-append-to-body="false">
        </el-option>
      </el-select>
      <el-cascader :props="provinceProps"
                   filterable
                   :show-all-levels="false"
                   v-model="currentProvince"
      ></el-cascader>
      <div class="ButtonBox">
        <div class="Button" style="background-color: #3D444F;" @click="(selectedTime = null, value1 = ['5'], currentProvince = '45'); getData();">重置</div>
        <div class="Button" style="background-color: #4584FF;" @click="getData">筛选</div>
      </div>
    </div>
    <div v-if="this.timeRangeActive">
    <div class="form" v-loading="formLoading">
      <div  v-for="item in firePointData.features" :key="item.properties.id" class="item" @click="goToFirePoint(item)">
        <div style="color: white;font-weight: bold;font-size: 14px;line-height: 14px;margin-top: 12px;margin-left: 24px">{{item.properties.createTime}}</div>
        <div class="text" style="margin-left: 24px;overflow: hidden;width: 16vw">{{item.properties.firePointName}}&nbsp;&nbsp;发现疑似火点</div>
        <div class="text">经度：{{item.properties.longitude}},维度：{{item.properties.latitude}}</div>
        <div class="text" style="margin-left: 10px">置信度为{{item.properties.reliability}}</div>
        <div class="text" style="float: right;margin-right: 10px">持续时间：{{secondToDHMS(item.properties.durationTime)}}</div>
      </div>
      <div class="total">共{{total}}项数据 <el-pagination
          background
          layout="prev, pager, next"
          :page-size="6"
          :total=total
          :current-page='currentPage'
          @current-change="pageChange">

      </el-pagination></div>
    </div>


    </div>
    <div v-if="areaRangeActive" id="map" v-loading="mapLoading"></div>
  </div>
  <el-dialog
      title="火点"
      :visible.sync="dialogVisible"
      width="65%">
    <div class="timeSelectInput">
      <el-date-picker
          style="flex: 2; margin-right: 20px;"
          v-model="selectedTime2"
          type="datetimerange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['12:00:00']"
          value-format="timestamp"
      >
      </el-date-picker>
      <el-select
          style="flex: 2; margin-right: 20px;" v-model="value2" multiple collapse-tags placeholder="请选择置信度" >
        <el-option
            v-for="item in reliabilityLists2"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <div class="ButtonBox">
        <div class="Button" style="background-color: #3D444F;" @click="(selectedTime2 = [new Date().getTime()- 31 * 24 * 3600 * 1000, new Date().getTime()], value2 = ['5']); getData();">重置</div>
        <div class="Button" style="background-color: #4584FF;" @click="getDialogData">筛选</div>
      </div>
    </div>
    <el-table
        :data="dialogFirePointData"
        style="width: 100%">
      <el-table-column
          prop="properties.createTime"
          label="观测时间"
          width="160">
      </el-table-column>
      <el-table-column
          prop="properties.firePointName"
          label="火点位置"
          width="380">
      </el-table-column>
      <el-table-column
          prop="properties.durationTime"
          label="持续时间"
      width="150">
      </el-table-column>
      <el-table-column
          prop="properties.reliability"
          label="置信度"
          width="80">
      </el-table-column>
    </el-table>
    <div class="total">共{{dialogTotal}}项数据</div>
    <el-pagination
        background
        layout="prev, pager, next"
        :page-size="8"
        :total=dialogTotal
        :current-page='dialogPage'
        @current-change="dialogPageChange"
    >
    </el-pagination>
  </el-dialog>
</div>
</template>

<script>
import {reQuest} from "@/request/axios";
import mapBoxGl from "mapbox-gl";
import {upDateLayer} from "@/tuil/mapTool";
import {secondToDHMS} from "@/tuil/commonTool";
import {GetAreaIdData} from "@/api/firePoint";
export default {
  name: "HistoricalFirePoint",
  data(){
    return{
      map:null,
      chinaRangeActive:true,
      worldRangeActive:false,
      timeRangeActive:true,
      areaRangeActive:false,
      selectedTime:[new Date().getTime()- 31 * 24 * 3600 * 1000, new Date().getTime()],
      selectedTime2:[new Date().getTime()- 31 * 24 * 3600 * 1000, new Date().getTime()],
      reliabilityLists: [{value: '1', label: '置信度1'}, {value: '2', label: '置信度2'}, {value: '3', label: '置信度3'}, {value: '4', label: '置信度4'}, {value: '5', label: '置信度5'}],
      value1:['5'],
      firePointData:'',
      total:0,
      currentPage:1,
      AreaTotal:0,
      mapStyle:{
        "version": 8,
        "name": "林火前台",
        "metadata": {
          "mapbox:type": "default",
          "mapbox:origin": "monochrome-dark-v1",
          "mapbox:sdk-support": {
            "android": "10.9.0",
            "ios": "10.9.0",
            "js": "2.11.1"
          },
          "mapbox:autocomposite": true,
          "mapbox:groups": {
            "Transit, transit-labels": {
              "name": "Transit, transit-labels",
              "collapsed": true
            },
            "Administrative boundaries, admin": {
              "name": "Administrative boundaries, admin",
              "collapsed": true
            },
            "Land & water, built": {
              "name": "Land & water, built",
              "collapsed": false
            },
            "Transit, bridges": {"name": "Transit, bridges", "collapsed": true},
            "Transit, surface": {
              "name": "Transit, surface",
              "collapsed": false
            },
            "Land & water, land": {
              "name": "Land & water, land",
              "collapsed": false
            },
            "Road network, bridges": {
              "name": "Road network, bridges",
              "collapsed": false
            },
            "Road network, tunnels": {
              "name": "Road network, tunnels",
              "collapsed": true
            },
            "Road network, road-labels": {
              "name": "Road network, road-labels",
              "collapsed": true
            },
            "Buildings, built": {
              "name": "Buildings, built",
              "collapsed": false
            },
            "Natural features, natural-labels": {
              "name": "Natural features, natural-labels",
              "collapsed": true
            },
            "Road network, surface": {
              "name": "Road network, surface",
              "collapsed": true
            },
            "Walking, cycling, etc., barriers-bridges": {
              "name": "Walking, cycling, etc., barriers-bridges",
              "collapsed": false
            },
            "Place labels, place-labels": {
              "name": "Place labels, place-labels",
              "collapsed": true
            },
            "Point of interest labels, poi-labels": {
              "name": "Point of interest labels, poi-labels",
              "collapsed": true
            },
            "Walking, cycling, etc., tunnels": {
              "name": "Walking, cycling, etc., tunnels",
              "collapsed": false
            },
            "Walking, cycling, etc., surface": {
              "name": "Walking, cycling, etc., surface",
              "collapsed": false
            },
            "Transit, built": {"name": "Transit, built", "collapsed": false},
            "Land & water, water": {
              "name": "Land & water, water",
              "collapsed": false
            }
          },
          "mapbox:uiParadigm": "layers",
          "mapbox:decompiler": {
            "id": "clei02qtz002001pnqqsuall1",
            "componentVersion": "16.0.0",
            "strata": [
              {
                "id": "monochrome-dark-v1",
                "order": [
                  ["land-and-water", "land"],
                  ["land-and-water", "water"],
                  ["land-and-water", "built"],
                  "mapbox-satellite",
                  ["transit", "built"],
                  ["buildings", "built"],
                  ["road-network", "tunnels-case"],
                  ["walking-cycling", "tunnels"],
                  ["road-network", "tunnels"],
                  ["transit", "ferries"],
                  ["walking-cycling", "surface"],
                  ["road-network", "surface"],
                  ["transit", "surface"],
                  ["road-network", "surface-icons"],
                  ["walking-cycling", "barriers-bridges"],
                  ["road-network", "bridges"],
                  ["transit", "bridges"],
                  ["buildings", "extruded"],
                  ["transit", "elevated"],
                  ["admin-boundaries", "admin"],
                  ["buildings", "building-labels"],
                  ["road-network", "road-labels"],
                  ["walking-cycling", "walking-cycling-labels"],
                  ["transit", "ferry-aerialway-labels"],
                  ["natural-features", "natural-labels"],
                  ["point-of-interest-labels", "poi-labels"],
                  ["transit", "transit-labels"],
                  ["place-labels", "place-labels"]
                ]
              }
            ],
            "overrides": {
              "place-labels": {
                "country-label": {
                  "layout": {
                    "text-field": [
                      "coalesce",
                      ["get", "name_zh-Hans"],
                      ["get", "name"]
                    ]
                  }
                },
                "state-label": {
                  "layout": {
                    "text-field": [
                      "coalesce",
                      ["get", "name_zh-Hans"],
                      ["get", "name"]
                    ]
                  }
                },
                "settlement-major-label": {
                  "layout": {
                    "text-field": [
                      "coalesce",
                      ["get", "name_zh-Hans"],
                      ["get", "name"]
                    ]
                  }
                },
                "settlement-minor-label": {
                  "layout": {
                    "text-field": [
                      "coalesce",
                      ["get", "name_zh-Hans"],
                      ["get", "name"]
                    ]
                  }
                },
                "settlement-subdivision-label": {
                  "layout": {
                    "text-field": [
                      "coalesce",
                      ["get", "name_zh-Hans"],
                      ["get", "name"]
                    ]
                  }
                },
                "continent-label": {
                  "layout": {
                    "text-field": [
                      "coalesce",
                      ["get", "name_zh-Hans"],
                      ["get", "name"]
                    ]
                  }
                }
              },
              "land-and-water": {
                "water": {
                  "paint": {
                    "fill-color": "hsl(215, 11%, 10%)",
                    "fill-opacity": [
                      "interpolate",
                      ["linear"],
                      ["zoom"],
                      11,
                      1,
                      14,
                      0.01
                    ]
                  }
                },
                "land": {
                  "paint": {
                    "background-opacity": [
                      "interpolate",
                      ["linear"],
                      ["zoom"],
                      11,
                      1,
                      14,
                      0.01
                    ],
                    "background-color": "hsl(0, 0%, 16%)"
                  }
                },
                "national-park": {"paint": {"fill-opacity": 0}},
                "landuse": {
                  "paint": {
                    "fill-opacity": [
                      "match",
                      ["get", "class"],
                      "residential",
                      0,
                      0
                    ]
                  }
                },
                "waterway": {
                  "paint": {
                    "line-opacity": [
                      "interpolate",
                      ["linear"],
                      ["zoom"],
                      8.5,
                      1,
                      11,
                      0.01
                    ]
                  }
                },
                "land-structure-line": {"paint": {"line-opacity": 1}}
              },
              "road-network": {
                "road-label-simple": {
                  "layout": {
                    "text-field": [
                      "coalesce",
                      ["get", "name_zh-Hans"],
                      ["get", "name"]
                    ]
                  }
                }
              },
              "natural-features": {
                "water-point-label": {
                  "layout": {
                    "text-field": [
                      "coalesce",
                      ["get", "name_zh-Hans"],
                      ["get", "name"]
                    ]
                  }
                },
                "water-line-label": {
                  "layout": {
                    "text-field": [
                      "coalesce",
                      ["get", "name_zh-Hans"],
                      ["get", "name"]
                    ]
                  }
                },
                "natural-point-label": {
                  "layout": {
                    "text-field": [
                      "coalesce",
                      ["get", "name_zh-Hans"],
                      ["get", "name"]
                    ]
                  }
                },
                "natural-line-label": {
                  "layout": {
                    "text-field": [
                      "coalesce",
                      ["get", "name_zh-Hans"],
                      ["get", "name"]
                    ]
                  }
                },
                "waterway-label": {
                  "layout": {
                    "text-field": [
                      "coalesce",
                      ["get", "name_zh-Hans"],
                      ["get", "name"]
                    ]
                  }
                }
              },
              "transit": {
                "aeroway-line": {
                  "paint": {
                    "line-opacity": [
                      "interpolate",
                      ["linear"],
                      ["zoom"],
                      10,
                      0,
                      11,
                      0
                    ]
                  }
                }
              }
            },
            "components": {
              "road-network": "16.0.0",
              "natural-features": "16.0.0",
              "place-labels": "16.0.0",
              "admin-boundaries": "16.0.0",
              "point-of-interest-labels": "16.0.0",
              "walking-cycling": "16.0.0",
              "transit": "16.0.0",
              "land-and-water": "16.0.0",
              "buildings": "16.0.0"
            },
            "propConfig": {
              "road-network": {
                "colorBase": "hsl(0, 0%, 16%)",
                "roadNetwork": "Simple",
                "roadWidth": 0.6
              },
              "natural-features": {
                "colorBase": "hsl(0, 0%, 16%)",
                "labelStyle": "Text only",
                "density": 1
              },
              "place-labels": {
                "colorBase": "hsl(0, 0%, 16%)",
                "settlementLabelStyle": "Text only",
                "settlementsDensity": 2,
                "settlementSubdivisionsDensity": 3
              },
              "admin-boundaries": {
                "colorBase": "hsl(0, 0%, 16%)",
                "admin0Width": 1.3
              },
              "point-of-interest-labels": {
                "colorBase": "hsl(0, 0%, 16%)",
                "labelStyle": "Text only",
                "density": 1,
                "poiEtcFont": ["DIN Pro Italic", "Arial Unicode MS Regular"]
              },
              "walking-cycling": {
                "walkingPathDashPattern": "Solid",
                "controlDashStyle": true,
                "golfHoleLabelLine": false,
                "walkingCyclingPisteBackground": false,
                "colorBase": "hsl(0, 0%, 16%)",
                "trailDashPattern": "Solid",
                "pedestrianPolygonFeatures": false,
                "cyclewayPisteDashPattern": "Solid",
                "labels": false
              },
              "transit": {
                "colorBase": "hsl(0, 0%, 16%)",
                "labelStyle": "Text only",
                "aerialways": false,
                "ferries": false,
                "transitLabels": false,
                "railwayStyle": false,
                "iconColorScheme": "Monochrome"
              },
              "land-and-water": {
                "colorBase": "hsl(0, 0%, 16%)",
                "landType": "Landuse only",
                "transitionLandOnZoom": true,
                "waterStyle": "Simple",
                "landuseDensity": 8
              },
              "buildings": {
                "colorBase": "hsl(0, 0%, 16%)",
                "houseNumbers": false
              }
            }
          }
        },
        "fog": {
          "range": [-1, 10],
          "color": "hsl(0, 0%, 0%)",
          "high-color": "hsl(0, 0%, 0%)",
          "space-color": "hsl(0, 0%, 0%)",
          "horizon-blend": 0.1,
          "star-intensity": 0
        },
        "sources": {
          "composite": {
            "url": "mapbox://mapbox.mapbox-streets-v8",
            "type": "vector"
          }
        },
        "sprite": "mapbox://sprites/2652202513/clei02qtz002001pnqqsuall1/encx7ltfckj2a4fu8s9jy68l5",
        "glyphs": "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
        "projection": {"name": "globe"},
        "layers": [
          {
            "id": "tunnel-path-trail",
            "type": "line",
            "metadata": {
              "mapbox:featureComponent": "walking-cycling",
              "mapbox:group": "Walking, cycling, etc., tunnels"
            },
            "source": "composite",
            "source-layer": "road",
            "minzoom": 14,
            "filter": [
              "all",
              ["==", ["get", "structure"], "tunnel"],
              ["==", ["get", "class"], "path"],
              [
                "match",
                ["get", "type"],
                ["hiking", "mountain_bike", "trail"],
                true,
                false
              ],
              ["==", ["geometry-type"], "LineString"]
            ],
            "layout": {"line-cap": "round"},
            "paint": {
              "line-width": [
                "interpolate",
                ["exponential", 1.5],
                ["zoom"],
                15,
                1,
                18,
                4
              ],
              "line-color": "hsl(0, 0%, 12%)",
              "line-dasharray": [10, 0]
            }
          },
          {
            "id": "tunnel-path-cycleway-piste",
            "type": "line",
            "metadata": {
              "mapbox:featureComponent": "walking-cycling",
              "mapbox:group": "Walking, cycling, etc., tunnels"
            },
            "source": "composite",
            "source-layer": "road",
            "minzoom": 12,
            "filter": [
              "all",
              ["==", ["get", "structure"], "tunnel"],
              ["==", ["get", "class"], "path"],
              ["match", ["get", "type"], ["cycleway", "piste"], true, false],
              ["==", ["geometry-type"], "LineString"]
            ],
            "layout": {},
            "paint": {
              "line-width": [
                "interpolate",
                ["exponential", 1.5],
                ["zoom"],
                15,
                1,
                18,
                4
              ],
              "line-color": "hsl(0, 0%, 12%)",
              "line-dasharray": [10, 0]
            }
          },
          {
            "id": "tunnel-path",
            "type": "line",
            "metadata": {
              "mapbox:featureComponent": "walking-cycling",
              "mapbox:group": "Walking, cycling, etc., tunnels"
            },
            "source": "composite",
            "source-layer": "road",
            "minzoom": 14,
            "filter": [
              "all",
              ["==", ["get", "structure"], "tunnel"],
              ["==", ["get", "class"], "path"],
              ["!=", ["get", "type"], "steps"],
              ["==", ["geometry-type"], "LineString"]
            ],
            "layout": {"line-cap": "round"},
            "paint": {
              "line-width": [
                "interpolate",
                ["exponential", 1.5],
                ["zoom"],
                15,
                1,
                18,
                4
              ],
              "line-color": "hsl(0, 0%, 12%)",
              "line-dasharray": [10, 0]
            }
          },
          {
            "id": "tunnel-steps",
            "type": "line",
            "metadata": {
              "mapbox:featureComponent": "walking-cycling",
              "mapbox:group": "Walking, cycling, etc., tunnels"
            },
            "source": "composite",
            "source-layer": "road",
            "minzoom": 14,
            "filter": [
              "all",
              ["==", ["get", "structure"], "tunnel"],
              ["==", ["get", "type"], "steps"],
              ["==", ["geometry-type"], "LineString"]
            ],
            "paint": {
              "line-width": [
                "interpolate",
                ["exponential", 1.5],
                ["zoom"],
                15,
                1,
                16,
                1.6,
                18,
                6
              ],
              "line-color": "hsl(0, 0%, 12%)",
              "line-dasharray": [
                "step",
                ["zoom"],
                ["literal", [1, 0]],
                15,
                ["literal", [1.75, 1]],
                16,
                ["literal", [1, 0.75]],
                17,
                ["literal", [0.3, 0.3]]
              ]
            }
          },
          {
            "id": "tunnel-pedestrian",
            "type": "line",
            "metadata": {
              "mapbox:featureComponent": "walking-cycling",
              "mapbox:group": "Walking, cycling, etc., tunnels"
            },
            "source": "composite",
            "source-layer": "road",
            "minzoom": 13,
            "filter": [
              "all",
              ["==", ["get", "structure"], "tunnel"],
              ["==", ["get", "class"], "pedestrian"],
              ["==", ["geometry-type"], "LineString"]
            ],
            "paint": {
              "line-width": [
                "interpolate",
                ["exponential", 1.5],
                ["zoom"],
                14,
                0.5,
                18,
                12
              ],
              "line-color": "hsl(0, 0%, 12%)",
              "line-dasharray": [
                "step",
                ["zoom"],
                ["literal", [1, 0]],
                15,
                ["literal", [1.5, 0.4]],
                16,
                ["literal", [1, 0.2]]
              ]
            }
          },
          {
            "id": "tunnel-simple",
            "type": "line",
            "metadata": {
              "mapbox:featureComponent": "road-network",
              "mapbox:group": "Road network, tunnels"
            },
            "source": "composite",
            "source-layer": "road",
            "minzoom": 13,
            "filter": [
              "all",
              ["==", ["get", "structure"], "tunnel"],
              [
                "step",
                ["zoom"],
                [
                  "match",
                  ["get", "class"],
                  [
                    "motorway",
                    "motorway_link",
                    "trunk",
                    "trunk_link",
                    "primary",
                    "secondary",
                    "tertiary",
                    "street",
                    "street_limited",
                    "primary_link",
                    "track"
                  ],
                  true,
                  false
                ],
                14,
                [
                  "match",
                  ["get", "class"],
                  [
                    "motorway",
                    "motorway_link",
                    "trunk",
                    "trunk_link",
                    "primary",
                    "primary_link",
                    "secondary",
                    "secondary_link",
                    "tertiary",
                    "tertiary_link",
                    "street",
                    "street_limited",
                    "service",
                    "track"
                  ],
                  true,
                  false
                ]
              ],
              ["==", ["geometry-type"], "LineString"]
            ],
            "paint": {
              "line-width": [
                "interpolate",
                ["exponential", 1.5],
                ["zoom"],
                13,
                [
                  "match",
                  ["get", "class"],
                  ["motorway", "trunk", "primary"],
                  2.4,
                  ["secondary", "tertiary"],
                  1.5,
                  [
                    "motorway_link",
                    "trunk_link",
                    "street",
                    "street_limited",
                    "primary_link"
                  ],
                  0.6,
                  0.5
                ],
                18,
                [
                  "match",
                  ["get", "class"],
                  ["motorway", "trunk", "primary"],
                  19.2,
                  ["secondary", "tertiary"],
                  15.6,
                  [
                    "motorway_link",
                    "trunk_link",
                    "street",
                    "street_limited",
                    "primary_link"
                  ],
                  10.799999999999999,
                  7.199999999999999
                ],
                22,
                [
                  "match",
                  ["get", "class"],
                  ["motorway", "trunk", "primary"],
                  192,
                  ["secondary", "tertiary"],
                  156,
                  [
                    "motorway_link",
                    "trunk_link",
                    "primary_link",
                    "street",
                    "street_limited"
                  ],
                  108,
                  72
                ]
              ],
              "line-color": "hsl(0, 0%, 24%)"
            }
          },
          {
            "id": "road-path-trail",
            "type": "line",
            "metadata": {
              "mapbox:featureComponent": "walking-cycling",
              "mapbox:group": "Walking, cycling, etc., surface"
            },
            "source": "composite",
            "source-layer": "road",
            "minzoom": 12,
            "filter": [
              "all",
              ["==", ["get", "class"], "path"],
              [
                "match",
                ["get", "type"],
                ["hiking", "mountain_bike", "trail"],
                true,
                false
              ],
              ["match", ["get", "structure"], ["none", "ford"], true, false],
              ["==", ["geometry-type"], "LineString"]
            ],
            "layout": {
              "line-cap": "round",
              "line-join": ["step", ["zoom"], "miter", 14, "round"]
            },
            "paint": {
              "line-width": [
                "interpolate",
                ["exponential", 1.5],
                ["zoom"],
                15,
                1,
                18,
                4
              ],
              "line-color": "hsl(0, 0%, 24%)",
              "line-dasharray": [10, 0]
            }
          },
          {
            "id": "road-path-cycleway-piste",
            "type": "line",
            "metadata": {
              "mapbox:featureComponent": "walking-cycling",
              "mapbox:group": "Walking, cycling, etc., surface"
            },
            "source": "composite",
            "source-layer": "road",
            "minzoom": 12,
            "filter": [
              "all",
              ["==", ["get", "class"], "path"],
              ["match", ["get", "type"], ["cycleway", "piste"], true, false],
              ["match", ["get", "structure"], ["none", "ford"], true, false],
              ["==", ["geometry-type"], "LineString"]
            ],
            "layout": {"line-join": ["step", ["zoom"], "miter", 14, "round"]},
            "paint": {
              "line-width": [
                "interpolate",
                ["exponential", 1.5],
                ["zoom"],
                15,
                1,
                18,
                4
              ],
              "line-color": "hsl(0, 0%, 24%)",
              "line-dasharray": [10, 0]
            }
          },
          {
            "id": "road-path",
            "type": "line",
            "metadata": {
              "mapbox:featureComponent": "walking-cycling",
              "mapbox:group": "Walking, cycling, etc., surface"
            },
            "source": "composite",
            "source-layer": "road",
            "minzoom": 12,
            "filter": [
              "all",
              ["==", ["get", "class"], "path"],
              [
                "step",
                ["zoom"],
                [
                  "!",
                  [
                    "match",
                    ["get", "type"],
                    ["steps", "sidewalk", "crossing"],
                    true,
                    false
                  ]
                ],
                16,
                ["!=", ["get", "type"], "steps"]
              ],
              ["match", ["get", "structure"], ["none", "ford"], true, false],
              ["==", ["geometry-type"], "LineString"]
            ],
            "layout": {
              "line-cap": "round",
              "line-join": ["step", ["zoom"], "miter", 14, "round"]
            },
            "paint": {
              "line-width": [
                "interpolate",
                ["exponential", 1.5],
                ["zoom"],
                13,
                0.5,
                14,
                1,
                15,
                1,
                18,
                4
              ],
              "line-color": "hsl(0, 0%, 24%)",
              "line-dasharray": [10, 0]
            }
          },
          {
            "id": "road-steps",
            "type": "line",
            "metadata": {
              "mapbox:featureComponent": "walking-cycling",
              "mapbox:group": "Walking, cycling, etc., surface"
            },
            "source": "composite",
            "source-layer": "road",
            "minzoom": 14,
            "filter": [
              "all",
              ["==", ["get", "type"], "steps"],
              ["match", ["get", "structure"], ["none", "ford"], true, false],
              ["==", ["geometry-type"], "LineString"]
            ],
            "layout": {"line-join": "round"},
            "paint": {
              "line-width": [
                "interpolate",
                ["exponential", 1.5],
                ["zoom"],
                15,
                1,
                16,
                1.6,
                18,
                6
              ],
              "line-color": "hsl(0, 0%, 24%)",
              "line-dasharray": [
                "step",
                ["zoom"],
                ["literal", [1, 0]],
                15,
                ["literal", [1.75, 1]],
                16,
                ["literal", [1, 0.75]],
                17,
                ["literal", [0.3, 0.3]]
              ]
            }
          },
          {
            "id": "road-pedestrian",
            "type": "line",
            "metadata": {
              "mapbox:featureComponent": "walking-cycling",
              "mapbox:group": "Walking, cycling, etc., surface"
            },
            "source": "composite",
            "source-layer": "road",
            "minzoom": 12,
            "filter": [
              "all",
              ["==", ["get", "class"], "pedestrian"],
              ["match", ["get", "structure"], ["none", "ford"], true, false],
              ["case", ["has", "layer"], [">=", ["get", "layer"], 0], true],
              ["==", ["geometry-type"], "LineString"]
            ],
            "layout": {"line-join": ["step", ["zoom"], "miter", 14, "round"]},
            "paint": {
              "line-width": [
                "interpolate",
                ["exponential", 1.5],
                ["zoom"],
                14,
                0.5,
                18,
                12
              ],
              "line-color": "hsl(0, 0%, 24%)",
              "line-dasharray": [
                "step",
                ["zoom"],
                ["literal", [1, 0]],
                15,
                ["literal", [1.5, 0.4]],
                16,
                ["literal", [1, 0.2]]
              ]
            }
          },
          {
            "id": "road-simple",
            "type": "line",
            "metadata": {
              "mapbox:featureComponent": "road-network",
              "mapbox:group": "Road network, surface"
            },
            "source": "composite",
            "source-layer": "road",
            "minzoom": 5,
            "filter": [
              "all",
              [
                "step",
                ["zoom"],
                [
                  "match",
                  ["get", "class"],
                  ["motorway", "trunk"],
                  true,
                  false
                ],
                6,
                [
                  "match",
                  ["get", "class"],
                  ["motorway", "trunk", "primary"],
                  true,
                  false
                ],
                8,
                [
                  "match",
                  ["get", "class"],
                  ["motorway", "trunk", "primary", "secondary"],
                  true,
                  false
                ],
                10,
                [
                  "match",
                  ["get", "class"],
                  [
                    "motorway",
                    "trunk",
                    "primary",
                    "secondary",
                    "tertiary",
                    "motorway_link",
                    "trunk_link"
                  ],
                  true,
                  false
                ],
                11,
                [
                  "match",
                  ["get", "class"],
                  [
                    "motorway",
                    "motorway_link",
                    "trunk",
                    "trunk_link",
                    "primary",
                    "secondary",
                    "tertiary",
                    "street"
                  ],
                  true,
                  false
                ],
                12,
                [
                  "match",
                  ["get", "class"],
                  [
                    "motorway",
                    "motorway_link",
                    "trunk",
                    "trunk_link",
                    "primary",
                    "secondary",
                    "tertiary",
                    "street",
                    "street_limited",
                    "primary_link"
                  ],
                  true,
                  false
                ],
                13,
                [
                  "match",
                  ["get", "class"],
                  [
                    "motorway",
                    "motorway_link",
                    "trunk",
                    "trunk_link",
                    "primary",
                    "secondary",
                    "tertiary",
                    "street",
                    "street_limited",
                    "primary_link",
                    "track"
                  ],
                  true,
                  false
                ],
                14,
                [
                  "match",
                  ["get", "class"],
                  [
                    "motorway",
                    "motorway_link",
                    "trunk",
                    "trunk_link",
                    "primary",
                    "primary_link",
                    "secondary",
                    "secondary_link",
                    "tertiary",
                    "tertiary_link",
                    "street",
                    "street_limited",
                    "service",
                    "track"
                  ],
                  true,
                  false
                ]
              ],
              ["match", ["get", "structure"], ["none", "ford"], true, false],
              ["==", ["geometry-type"], "LineString"]
            ],
            "layout": {
              "line-cap": ["step", ["zoom"], "butt", 14, "round"],
              "line-join": ["step", ["zoom"], "miter", 14, "round"]
            },
            "paint": {
              "line-width": [
                "interpolate",
                ["exponential", 1.5],
                ["zoom"],
                5,
                [
                  "match",
                  ["get", "class"],
                  ["motorway", "trunk", "primary"],
                  0.44999999999999996,
                  ["secondary", "tertiary"],
                  0.06,
                  0
                ],
                13,
                [
                  "match",
                  ["get", "class"],
                  ["motorway", "trunk", "primary"],
                  2.4,
                  ["secondary", "tertiary"],
                  1.5,
                  [
                    "motorway_link",
                    "trunk_link",
                    "primary_link",
                    "street",
                    "street_limited"
                  ],
                  0.6,
                  0.3
                ],
                18,
                [
                  "match",
                  ["get", "class"],
                  ["motorway", "trunk", "primary"],
                  19.2,
                  ["secondary", "tertiary"],
                  15.6,
                  [
                    "motorway_link",
                    "trunk_link",
                    "primary_link",
                    "street",
                    "street_limited"
                  ],
                  10.799999999999999,
                  6
                ],
                22,
                [
                  "match",
                  ["get", "class"],
                  ["motorway", "trunk", "primary"],
                  192,
                  ["secondary", "tertiary"],
                  156,
                  [
                    "motorway_link",
                    "trunk_link",
                    "primary_link",
                    "street",
                    "street_limited"
                  ],
                  108,
                  60
                ]
              ],
              "line-color": "hsl(0, 0%, 24%)"
            }
          },
          {
            "id": "road-rail",
            "type": "line",
            "metadata": {
              "mapbox:featureComponent": "transit",
              "mapbox:group": "Transit, surface"
            },
            "source": "composite",
            "source-layer": "road",
            "minzoom": 13,
            "filter": [
              "all",
              [
                "match",
                ["get", "class"],
                ["major_rail", "minor_rail"],
                true,
                false
              ],
              ["match", ["get", "structure"], ["none", "ford"], true, false]
            ],
            "paint": {
              "line-color": [
                "interpolate",
                ["linear"],
                ["zoom"],
                13,
                "hsl(0, 0%, 14%)",
                17,
                "hsl(0, 0%, 12%)"
              ],
              "line-width": [
                "interpolate",
                ["exponential", 1.5],
                ["zoom"],
                14,
                0.5,
                20,
                1
              ]
            }
          },
          {
            "id": "bridge-path-trail",
            "type": "line",
            "metadata": {
              "mapbox:featureComponent": "walking-cycling",
              "mapbox:group": "Walking, cycling, etc., barriers-bridges"
            },
            "source": "composite",
            "source-layer": "road",
            "minzoom": 14,
            "filter": [
              "all",
              ["==", ["get", "structure"], "bridge"],
              ["==", ["get", "class"], "path"],
              [
                "match",
                ["get", "type"],
                ["hiking", "mountain_bike", "trail"],
                true,
                false
              ],
              ["==", ["geometry-type"], "LineString"]
            ],
            "layout": {"line-cap": "round"},
            "paint": {
              "line-width": [
                "interpolate",
                ["exponential", 1.5],
                ["zoom"],
                15,
                1,
                18,
                4
              ],
              "line-color": "hsl(0, 0%, 24%)",
              "line-dasharray": [10, 0]
            }
          },
          {
            "id": "bridge-path-cycleway-piste",
            "type": "line",
            "metadata": {
              "mapbox:featureComponent": "walking-cycling",
              "mapbox:group": "Walking, cycling, etc., barriers-bridges"
            },
            "source": "composite",
            "source-layer": "road",
            "minzoom": 14,
            "filter": [
              "all",
              ["==", ["get", "structure"], "bridge"],
              ["==", ["get", "class"], "path"],
              ["match", ["get", "type"], ["cycleway", "piste"], true, false],
              ["==", ["geometry-type"], "LineString"]
            ],
            "layout": {},
            "paint": {
              "line-width": [
                "interpolate",
                ["exponential", 1.5],
                ["zoom"],
                15,
                1,
                18,
                4
              ],
              "line-color": "hsl(0, 0%, 24%)",
              "line-dasharray": [10, 0]
            }
          },
          {
            "id": "bridge-path",
            "type": "line",
            "metadata": {
              "mapbox:featureComponent": "walking-cycling",
              "mapbox:group": "Walking, cycling, etc., barriers-bridges"
            },
            "source": "composite",
            "source-layer": "road",
            "minzoom": 14,
            "filter": [
              "all",
              ["==", ["get", "structure"], "bridge"],
              ["==", ["get", "class"], "path"],
              ["!=", ["get", "type"], "steps"],
              ["==", ["geometry-type"], "LineString"]
            ],
            "layout": {"line-cap": "round"},
            "paint": {
              "line-width": [
                "interpolate",
                ["exponential", 1.5],
                ["zoom"],
                15,
                1,
                18,
                4
              ],
              "line-color": "hsl(0, 0%, 24%)",
              "line-dasharray": [10, 0]
            }
          },
          {
            "id": "bridge-steps",
            "type": "line",
            "metadata": {
              "mapbox:featureComponent": "walking-cycling",
              "mapbox:group": "Walking, cycling, etc., barriers-bridges"
            },
            "source": "composite",
            "source-layer": "road",
            "minzoom": 14,
            "filter": [
              "all",
              ["==", ["get", "type"], "steps"],
              ["==", ["get", "structure"], "bridge"],
              ["==", ["geometry-type"], "LineString"]
            ],
            "paint": {
              "line-width": [
                "interpolate",
                ["exponential", 1.5],
                ["zoom"],
                15,
                1,
                16,
                1.6,
                18,
                6
              ],
              "line-color": "hsl(0, 0%, 24%)",
              "line-dasharray": [
                "step",
                ["zoom"],
                ["literal", [1, 0]],
                15,
                ["literal", [1.75, 1]],
                16,
                ["literal", [1, 0.75]],
                17,
                ["literal", [0.3, 0.3]]
              ]
            }
          },
          {
            "id": "bridge-pedestrian",
            "type": "line",
            "metadata": {
              "mapbox:featureComponent": "walking-cycling",
              "mapbox:group": "Walking, cycling, etc., barriers-bridges"
            },
            "source": "composite",
            "source-layer": "road",
            "minzoom": 13,
            "filter": [
              "all",
              ["==", ["get", "structure"], "bridge"],
              ["==", ["get", "class"], "pedestrian"],
              ["==", ["geometry-type"], "LineString"]
            ],
            "paint": {
              "line-width": [
                "interpolate",
                ["exponential", 1.5],
                ["zoom"],
                14,
                0.5,
                18,
                12
              ],
              "line-color": "hsl(0, 0%, 24%)",
              "line-dasharray": [
                "step",
                ["zoom"],
                ["literal", [1, 0]],
                15,
                ["literal", [1.5, 0.4]],
                16,
                ["literal", [1, 0.2]]
              ]
            }
          },
          {
            "id": "bridge-case-simple",
            "type": "line",
            "metadata": {
              "mapbox:featureComponent": "road-network",
              "mapbox:group": "Road network, bridges"
            },
            "source": "composite",
            "source-layer": "road",
            "minzoom": 13,
            "filter": [
              "all",
              ["==", ["get", "structure"], "bridge"],
              [
                "step",
                ["zoom"],
                [
                  "match",
                  ["get", "class"],
                  [
                    "motorway",
                    "motorway_link",
                    "trunk",
                    "trunk_link",
                    "primary",
                    "secondary",
                    "tertiary",
                    "street",
                    "street_limited",
                    "primary_link",
                    "track"
                  ],
                  true,
                  false
                ],
                14,
                [
                  "match",
                  ["get", "class"],
                  [
                    "motorway",
                    "motorway_link",
                    "trunk",
                    "trunk_link",
                    "primary",
                    "primary_link",
                    "secondary",
                    "secondary_link",
                    "tertiary",
                    "tertiary_link",
                    "street",
                    "street_limited",
                    "service",
                    "track"
                  ],
                  true,
                  false
                ]
              ],
              ["==", ["geometry-type"], "LineString"]
            ],
            "paint": {
              "line-width": [
                "interpolate",
                ["exponential", 1.5],
                ["zoom"],
                13,
                [
                  "match",
                  ["get", "class"],
                  ["motorway", "trunk", "primary"],
                  3.5999999999999996,
                  ["secondary", "tertiary"],
                  2.4,
                  [
                    "motorway_link",
                    "trunk_link",
                    "street",
                    "street_limited",
                    "primary_link"
                  ],
                  1.5,
                  0.75
                ],
                18,
                [
                  "match",
                  ["get", "class"],
                  ["motorway", "trunk", "primary"],
                  21.599999999999998,
                  ["secondary", "tertiary"],
                  18,
                  [
                    "motorway_link",
                    "trunk_link",
                    "street",
                    "street_limited",
                    "primary_link"
                  ],
                  13.2,
                  9.6
                ],
                22,
                [
                  "match",
                  ["get", "class"],
                  ["motorway", "trunk", "primary"],
                  216,
                  ["secondary", "tertiary"],
                  180,
                  [
                    "motorway_link",
                    "trunk_link",
                    "primary_link",
                    "street",
                    "street_limited"
                  ],
                  132,
                  96
                ]
              ],
              "line-color": "hsl(0, 0%, 16%)"
            }
          },
          {
            "id": "bridge-simple",
            "type": "line",
            "metadata": {
              "mapbox:featureComponent": "road-network",
              "mapbox:group": "Road network, bridges"
            },
            "source": "composite",
            "source-layer": "road",
            "minzoom": 13,
            "filter": [
              "all",
              ["==", ["get", "structure"], "bridge"],
              [
                "step",
                ["zoom"],
                [
                  "match",
                  ["get", "class"],
                  ["motorway", "trunk"],
                  true,
                  false
                ],
                13,
                [
                  "match",
                  ["get", "class"],
                  [
                    "motorway",
                    "motorway_link",
                    "trunk",
                    "trunk_link",
                    "primary",
                    "secondary",
                    "tertiary",
                    "street",
                    "street_limited",
                    "primary_link",
                    "track"
                  ],
                  true,
                  false
                ],
                14,
                [
                  "match",
                  ["get", "class"],
                  [
                    "motorway",
                    "motorway_link",
                    "trunk",
                    "trunk_link",
                    "primary",
                    "primary_link",
                    "secondary",
                    "secondary_link",
                    "tertiary",
                    "tertiary_link",
                    "street",
                    "street_limited",
                    "service",
                    "track"
                  ],
                  true,
                  false
                ]
              ],
              ["==", ["geometry-type"], "LineString"]
            ],
            "layout": {"line-cap": ["step", ["zoom"], "butt", 14, "round"]},
            "paint": {
              "line-width": [
                "interpolate",
                ["exponential", 1.5],
                ["zoom"],
                13,
                [
                  "match",
                  ["get", "class"],
                  ["motorway", "trunk", "primary"],
                  2.4,
                  ["secondary", "tertiary"],
                  1.5,
                  [
                    "motorway_link",
                    "trunk_link",
                    "street",
                    "street_limited",
                    "primary_link"
                  ],
                  0.6,
                  0.5
                ],
                18,
                [
                  "match",
                  ["get", "class"],
                  ["motorway", "trunk", "primary"],
                  19.2,
                  ["secondary", "tertiary"],
                  15.6,
                  [
                    "motorway_link",
                    "trunk_link",
                    "street",
                    "street_limited",
                    "primary_link"
                  ],
                  10.799999999999999,
                  7.199999999999999
                ],
                22,
                [
                  "match",
                  ["get", "class"],
                  ["motorway", "trunk", "primary"],
                  192,
                  ["secondary", "tertiary"],
                  156,
                  [
                    "motorway_link",
                    "trunk_link",
                    "primary_link",
                    "street",
                    "street_limited"
                  ],
                  108,
                  72
                ]
              ],
              "line-color": "hsl(0, 0%, 24%)"
            }
          },
          {
            "id": "bridge-rail",
            "type": "line",
            "metadata": {
              "mapbox:featureComponent": "transit",
              "mapbox:group": "Transit, bridges"
            },
            "source": "composite",
            "source-layer": "road",
            "minzoom": 13,
            "filter": [
              "all",
              ["==", ["get", "structure"], "bridge"],
              [
                "match",
                ["get", "class"],
                ["major_rail", "minor_rail"],
                true,
                false
              ]
            ],
            "paint": {
              "line-color": "hsl(0, 0%, 12%)",
              "line-width": [
                "interpolate",
                ["exponential", 1.5],
                ["zoom"],
                14,
                0.5,
                20,
                1
              ]
            }
          },
          {
            "id": "admin-1-boundary-bg",
            "type": "line",
            "metadata": {
              "mapbox:featureComponent": "admin-boundaries",
              "mapbox:group": "Administrative boundaries, admin"
            },
            "source": "composite",
            "source-layer": "admin",
            "minzoom": 7,
            "filter": [
              "all",
              ["==", ["get", "admin_level"], 1],
              ["==", ["get", "maritime"], "false"],
              ["match", ["get", "worldview"], ["all", "US"], true, false]
            ],
            "paint": {
              "line-color": "hsl(0, 0%, 12%)",
              "line-width": [
                "interpolate",
                ["linear"],
                ["zoom"],
                3,
                3,
                12,
                6
              ],
              "line-opacity": [
                "interpolate",
                ["linear"],
                ["zoom"],
                7,
                0,
                8,
                0.5
              ],
              "line-dasharray": [1, 0],
              "line-blur": ["interpolate", ["linear"], ["zoom"], 3, 0, 12, 3]
            }
          },
          {
            "id": "admin-0-boundary-bg",
            "type": "line",
            "metadata": {
              "mapbox:featureComponent": "admin-boundaries",
              "mapbox:group": "Administrative boundaries, admin"
            },
            "source": "composite",
            "source-layer": "admin",
            "minzoom": 1,
            "filter": [
              "all",
              ["==", ["get", "admin_level"], 0],
              ["==", ["get", "maritime"], "false"],
              ["match", ["get", "worldview"], ["all", "US"], true, false]
            ],
            "paint": {
              "line-width": [
                "interpolate",
                ["linear"],
                ["zoom"],
                3,
                5.2,
                12,
                10.4
              ],
              "line-color": "hsl(0, 0%, 12%)",
              "line-opacity": [
                "interpolate",
                ["linear"],
                ["zoom"],
                3,
                0,
                4,
                0.5
              ],
              "line-blur": [
                "interpolate",
                ["linear"],
                ["zoom"],
                3,
                0,
                12,
                2.6
              ]
            }
          },
          {
            "id": "admin-1-boundary",
            "type": "line",
            "metadata": {
              "mapbox:featureComponent": "admin-boundaries",
              "mapbox:group": "Administrative boundaries, admin"
            },
            "source": "composite",
            "source-layer": "admin",
            "minzoom": 2,
            "filter": [
              "all",
              ["==", ["get", "admin_level"], 1],
              ["==", ["get", "maritime"], "false"],
              ["match", ["get", "worldview"], ["all", "US"], true, false]
            ],
            "layout": {},
            "paint": {
              "line-dasharray": [
                "step",
                ["zoom"],
                ["literal", [2, 0]],
                7,
                ["literal", [2, 2, 6, 2]]
              ],
              "line-width": [
                "interpolate",
                ["linear"],
                ["zoom"],
                3,
                0.3,
                12,
                1.5
              ],
              "line-opacity": [
                "interpolate",
                ["linear"],
                ["zoom"],
                2,
                0,
                3,
                1
              ],
              "line-color": "hsl(0, 0%, 38%)"
            }
          },
          {
            "id": "admin-0-boundary",
            "type": "line",
            "metadata": {
              "mapbox:featureComponent": "admin-boundaries",
              "mapbox:group": "Administrative boundaries, admin"
            },
            "source": "composite",
            "source-layer": "admin",
            "minzoom": 1,
            "filter": [
              "all",
              ["==", ["get", "admin_level"], 0],
              ["==", ["get", "disputed"], "false"],
              ["==", ["get", "maritime"], "false"],
              ["match", ["get", "worldview"], ["all", "US"], true, false]
            ],
            "layout": {},
            "paint": {
              "line-color": "hsl(0, 0%, 41%)",
              "line-width": [
                "interpolate",
                ["linear"],
                ["zoom"],
                3,
                0.65,
                12,
                2.6
              ],
              "line-dasharray": [10, 0]
            }
          },
          {
            "id": "admin-0-boundary-disputed",
            "type": "line",
            "metadata": {
              "mapbox:featureComponent": "admin-boundaries",
              "mapbox:group": "Administrative boundaries, admin"
            },
            "source": "composite",
            "source-layer": "admin",
            "minzoom": 1,
            "filter": [
              "all",
              ["==", ["get", "disputed"], "true"],
              ["==", ["get", "admin_level"], 0],
              ["==", ["get", "maritime"], "false"],
              ["match", ["get", "worldview"], ["all", "US"], true, false]
            ],
            "paint": {
              "line-color": "hsl(0, 0%, 41%)",
              "line-width": [
                "interpolate",
                ["linear"],
                ["zoom"],
                3,
                0.65,
                12,
                2.6
              ],
              "line-dasharray": [
                "step",
                ["zoom"],
                ["literal", [3, 2, 5]],
                7,
                ["literal", [2, 1.5]]
              ]
            }
          },
          {
            "id": "road-label-simple",
            "type": "symbol",
            "metadata": {
              "mapbox:featureComponent": "road-network",
              "mapbox:group": "Road network, road-labels"
            },
            "source": "composite",
            "source-layer": "road",
            "minzoom": 12,
            "filter": [
              "all",
              ["has", "name"],
              [
                "match",
                ["get", "class"],
                [
                  "motorway",
                  "trunk",
                  "primary",
                  "secondary",
                  "tertiary",
                  "street",
                  "street_limited"
                ],
                true,
                false
              ]
            ],
            "layout": {
              "text-size": [
                "interpolate",
                ["linear"],
                ["zoom"],
                10,
                [
                  "match",
                  ["get", "class"],
                  [
                    "motorway",
                    "trunk",
                    "primary",
                    "secondary",
                    "tertiary"
                  ],
                  10,
                  9
                ],
                18,
                [
                  "match",
                  ["get", "class"],
                  [
                    "motorway",
                    "trunk",
                    "primary",
                    "secondary",
                    "tertiary"
                  ],
                  16,
                  14
                ]
              ],
              "text-max-angle": 30,
              "text-font": ["DIN Pro Regular", "Arial Unicode MS Regular"],
              "symbol-placement": "line",
              "text-padding": 1,
              "text-rotation-alignment": "map",
              "text-pitch-alignment": "viewport",
              "text-field": [
                "coalesce",
                ["get", "name_zh-Hans"],
                ["get", "name"]
              ],
              "text-letter-spacing": 0.01
            },
            "paint": {
              "text-color": "hsl(0, 0%, 66%)",
              "text-halo-color": "hsl(0, 0%, 3%)",
              "text-halo-width": 1
            }
          },
          {
            "id": "waterway-label",
            "type": "symbol",
            "metadata": {
              "mapbox:featureComponent": "natural-features",
              "mapbox:group": "Natural features, natural-labels"
            },
            "source": "composite",
            "source-layer": "natural_label",
            "minzoom": 13,
            "filter": [
              "all",
              [
                "match",
                ["get", "class"],
                [
                  "canal",
                  "river",
                  "stream",
                  "disputed_canal",
                  "disputed_river",
                  "disputed_stream"
                ],
                ["match", ["get", "worldview"], ["all", "US"], true, false],
                false
              ],
              ["==", ["geometry-type"], "LineString"]
            ],
            "layout": {
              "text-font": ["DIN Pro Italic", "Arial Unicode MS Regular"],
              "text-max-angle": 30,
              "symbol-spacing": [
                "interpolate",
                ["linear", 1],
                ["zoom"],
                15,
                250,
                17,
                400
              ],
              "text-size": [
                "interpolate",
                ["linear"],
                ["zoom"],
                13,
                12,
                18,
                18
              ],
              "symbol-placement": "line",
              "text-pitch-alignment": "viewport",
              "text-field": [
                "coalesce",
                ["get", "name_zh-Hans"],
                ["get", "name"]
              ]
            },
            "paint": {
              "text-color": "hsl(0, 0%, 35%)",
              "text-halo-color": "hsla(0, 0%, 3%, 0.5)"
            }
          },
          {
            "id": "natural-line-label",
            "type": "symbol",
            "metadata": {
              "mapbox:featureComponent": "natural-features",
              "mapbox:group": "Natural features, natural-labels"
            },
            "source": "composite",
            "source-layer": "natural_label",
            "minzoom": 4,
            "filter": [
              "all",
              [
                "match",
                ["get", "class"],
                [
                  "glacier",
                  "landform",
                  "disputed_glacier",
                  "disputed_landform"
                ],
                ["match", ["get", "worldview"], ["all", "US"], true, false],
                false
              ],
              ["<=", ["get", "filterrank"], 1],
              ["==", ["geometry-type"], "LineString"]
            ],
            "layout": {
              "text-size": [
                "step",
                ["zoom"],
                ["step", ["get", "sizerank"], 18, 5, 12],
                17,
                ["step", ["get", "sizerank"], 18, 13, 12]
              ],
              "text-max-angle": 30,
              "text-field": [
                "coalesce",
                ["get", "name_zh-Hans"],
                ["get", "name"]
              ],
              "text-font": ["DIN Pro Medium", "Arial Unicode MS Regular"],
              "symbol-placement": "line-center",
              "text-pitch-alignment": "viewport"
            },
            "paint": {
              "text-halo-width": 0.5,
              "text-halo-color": "hsl(0, 0%, 3%)",
              "text-halo-blur": 0.5,
              "text-color": "hsl(0, 0%, 53%)"
            }
          },
          {
            "id": "natural-point-label",
            "type": "symbol",
            "metadata": {
              "mapbox:featureComponent": "natural-features",
              "mapbox:group": "Natural features, natural-labels"
            },
            "source": "composite",
            "source-layer": "natural_label",
            "minzoom": 4,
            "filter": [
              "all",
              [
                "match",
                ["get", "class"],
                [
                  "dock",
                  "glacier",
                  "landform",
                  "water_feature",
                  "wetland",
                  "disputed_dock",
                  "disputed_glacier",
                  "disputed_landform",
                  "disputed_water_feature",
                  "disputed_wetland"
                ],
                ["match", ["get", "worldview"], ["all", "US"], true, false],
                false
              ],
              ["<=", ["get", "filterrank"], 1],
              ["==", ["geometry-type"], "Point"]
            ],
            "layout": {
              "text-size": [
                "step",
                ["zoom"],
                ["step", ["get", "sizerank"], 18, 5, 12],
                17,
                ["step", ["get", "sizerank"], 18, 13, 12]
              ],
              "icon-image": "",
              "text-font": ["DIN Pro Medium", "Arial Unicode MS Regular"],
              "text-offset": ["literal", [0, 0]],
              "text-field": [
                "coalesce",
                ["get", "name_zh-Hans"],
                ["get", "name"]
              ]
            },
            "paint": {
              "icon-opacity": [
                "step",
                ["zoom"],
                ["step", ["get", "sizerank"], 0, 5, 1],
                17,
                ["step", ["get", "sizerank"], 0, 13, 1]
              ],
              "text-halo-color": "hsl(0, 0%, 3%)",
              "text-halo-width": 0.5,
              "text-halo-blur": 0.5,
              "text-color": "hsl(0, 0%, 53%)"
            }
          },
          {
            "id": "water-line-label",
            "type": "symbol",
            "metadata": {
              "mapbox:featureComponent": "natural-features",
              "mapbox:group": "Natural features, natural-labels"
            },
            "source": "composite",
            "source-layer": "natural_label",
            "minzoom": 1,
            "filter": [
              "all",
              [
                "match",
                ["get", "class"],
                [
                  "bay",
                  "ocean",
                  "reservoir",
                  "sea",
                  "water",
                  "disputed_bay",
                  "disputed_ocean",
                  "disputed_reservoir",
                  "disputed_sea",
                  "disputed_water"
                ],
                ["match", ["get", "worldview"], ["all", "US"], true, false],
                false
              ],
              ["==", ["geometry-type"], "LineString"]
            ],
            "layout": {
              "text-size": [
                "interpolate",
                ["linear"],
                ["zoom"],
                0,
                ["*", ["-", 16, ["sqrt", ["get", "sizerank"]]], 1],
                22,
                ["*", ["-", 22, ["sqrt", ["get", "sizerank"]]], 1]
              ],
              "text-max-angle": 30,
              "text-letter-spacing": [
                "match",
                ["get", "class"],
                "ocean",
                0.25,
                ["sea", "bay"],
                0.15,
                0
              ],
              "text-font": ["DIN Pro Italic", "Arial Unicode MS Regular"],
              "symbol-placement": "line-center",
              "text-pitch-alignment": "viewport",
              "text-field": [
                "coalesce",
                ["get", "name_zh-Hans"],
                ["get", "name"]
              ]
            },
            "paint": {
              "text-color": "hsl(0, 0%, 35%)",
              "text-halo-color": "hsla(0, 0%, 3%, 0.5)"
            }
          },
          {
            "id": "water-point-label",
            "type": "symbol",
            "metadata": {
              "mapbox:featureComponent": "natural-features",
              "mapbox:group": "Natural features, natural-labels"
            },
            "source": "composite",
            "source-layer": "natural_label",
            "minzoom": 1,
            "filter": [
              "all",
              [
                "match",
                ["get", "class"],
                [
                  "bay",
                  "ocean",
                  "reservoir",
                  "sea",
                  "water",
                  "disputed_bay",
                  "disputed_ocean",
                  "disputed_reservoir",
                  "disputed_sea",
                  "disputed_water"
                ],
                ["match", ["get", "worldview"], ["all", "US"], true, false],
                false
              ],
              ["==", ["geometry-type"], "Point"]
            ],
            "layout": {
              "text-line-height": 1.3,
              "text-size": [
                "interpolate",
                ["linear"],
                ["zoom"],
                0,
                ["*", ["-", 16, ["sqrt", ["get", "sizerank"]]], 1],
                22,
                ["*", ["-", 22, ["sqrt", ["get", "sizerank"]]], 1]
              ],
              "text-font": ["DIN Pro Italic", "Arial Unicode MS Regular"],
              "text-field": [
                "coalesce",
                ["get", "name_zh-Hans"],
                ["get", "name"]
              ],
              "text-letter-spacing": [
                "match",
                ["get", "class"],
                "ocean",
                0.25,
                ["bay", "sea"],
                0.15,
                0.01
              ],
              "text-max-width": [
                "match",
                ["get", "class"],
                "ocean",
                4,
                "sea",
                5,
                ["bay", "water"],
                7,
                10
              ]
            },
            "paint": {
              "text-color": "hsl(0, 0%, 35%)",
              "text-halo-color": "hsla(0, 0%, 3%, 0.5)"
            }
          },
          {
            "id": "poi-label",
            "type": "symbol",
            "metadata": {
              "mapbox:featureComponent": "point-of-interest-labels",
              "mapbox:group": "Point of interest labels, poi-labels"
            },
            "source": "composite",
            "source-layer": "poi_label",
            "minzoom": 6,
            "filter": [
              "<=",
              ["get", "filterrank"],
              ["+", ["step", ["zoom"], 0, 16, 1, 17, 2], 1]
            ],
            "layout": {
              "text-size": [
                "step",
                ["zoom"],
                ["step", ["get", "sizerank"], 18, 5, 12],
                17,
                ["step", ["get", "sizerank"], 18, 13, 12]
              ],
              "icon-image": "",
              "text-font": ["DIN Pro Italic", "Arial Unicode MS Regular"],
              "text-offset": [0, 0],
              "text-anchor": [
                "step",
                ["zoom"],
                ["step", ["get", "sizerank"], "center", 5, "top"],
                17,
                ["step", ["get", "sizerank"], "center", 13, "top"]
              ],
              "text-field": ["coalesce", ["get", "name_en"], ["get", "name"]]
            },
            "paint": {
              "text-halo-color": "hsl(0, 0%, 3%)",
              "text-halo-width": 0.5,
              "text-halo-blur": 0.5,
              "text-color": "hsl(0, 0%, 53%)"
            }
          },
          {
            "id": "airport-label",
            "type": "symbol",
            "metadata": {
              "mapbox:featureComponent": "transit",
              "mapbox:group": "Transit, transit-labels"
            },
            "source": "composite",
            "source-layer": "airport_label",
            "minzoom": 8,
            "filter": [
              "match",
              ["get", "class"],
              ["military", "civil", "disputed_military", "disputed_civil"],
              ["match", ["get", "worldview"], ["all", "US"], true, false],
              false
            ],
            "layout": {
              "text-line-height": 1.1,
              "text-size": ["step", ["get", "sizerank"], 18, 9, 12],
              "icon-image": "",
              "text-font": ["DIN Pro Medium", "Arial Unicode MS Regular"],
              "text-offset": [0, 0],
              "text-rotation-alignment": "viewport",
              "text-anchor": "top",
              "text-field": [
                "step",
                ["get", "sizerank"],
                [
                  "case",
                  ["has", "ref"],
                  [
                    "concat",
                    ["get", "ref"],
                    " -\n",
                    ["coalesce", ["get", "name_en"], ["get", "name"]]
                  ],
                  ["coalesce", ["get", "name_en"], ["get", "name"]]
                ],
                15,
                ["get", "ref"]
              ],
              "text-letter-spacing": 0.01,
              "text-max-width": 9
            },
            "paint": {
              "text-color": "hsl(0, 0%, 66%)",
              "text-halo-color": "hsl(0, 0%, 3%)",
              "text-halo-width": 1
            }
          },
          {
            "id": "settlement-subdivision-label",
            "type": "symbol",
            "metadata": {
              "mapbox:featureComponent": "place-labels",
              "mapbox:group": "Place labels, place-labels"
            },
            "source": "composite",
            "source-layer": "place_label",
            "minzoom": 10,
            "maxzoom": 15,
            "filter": [
              "all",
              [
                "match",
                ["get", "class"],
                [
                  "settlement_subdivision",
                  "disputed_settlement_subdivision"
                ],
                ["match", ["get", "worldview"], ["all", "US"], true, false],
                false
              ],
              ["<=", ["get", "filterrank"], 3]
            ],
            "layout": {
              "text-field": [
                "coalesce",
                ["get", "name_zh-Hans"],
                ["get", "name"]
              ],
              "text-transform": "uppercase",
              "text-font": ["DIN Pro Regular", "Arial Unicode MS Regular"],
              "text-letter-spacing": [
                "match",
                ["get", "type"],
                "suburb",
                0.15,
                0.05
              ],
              "text-max-width": 7,
              "text-padding": 3,
              "text-size": [
                "interpolate",
                ["cubic-bezier", 0.5, 0, 1, 1],
                ["zoom"],
                11,
                ["match", ["get", "type"], "suburb", 11, 10.5],
                15,
                ["match", ["get", "type"], "suburb", 15, 14]
              ]
            },
            "paint": {
              "text-halo-color": "hsl(0, 0%, 3%)",
              "text-halo-width": 1,
              "text-color": "hsl(0, 0%, 54%)",
              "text-halo-blur": 0.5
            }
          },
          {
            "id": "settlement-minor-label",
            "type": "symbol",
            "metadata": {
              "mapbox:featureComponent": "place-labels",
              "mapbox:group": "Place labels, place-labels"
            },
            "source": "composite",
            "source-layer": "place_label",
            "minzoom": 2,
            "maxzoom": 13,
            "filter": [
              "all",
              ["<=", ["get", "filterrank"], 2],
              [
                "match",
                ["get", "class"],
                ["settlement", "disputed_settlement"],
                ["match", ["get", "worldview"], ["all", "US"], true, false],
                false
              ],
              [
                "step",
                ["zoom"],
                [">", ["get", "symbolrank"], 6],
                4,
                [">=", ["get", "symbolrank"], 7],
                6,
                [">=", ["get", "symbolrank"], 8],
                7,
                [">=", ["get", "symbolrank"], 10],
                10,
                [">=", ["get", "symbolrank"], 11],
                11,
                [">=", ["get", "symbolrank"], 13],
                12,
                [">=", ["get", "symbolrank"], 15]
              ]
            ],
            "layout": {
              "text-line-height": 1.1,
              "text-size": [
                "interpolate",
                ["cubic-bezier", 0.2, 0, 0.9, 1],
                ["zoom"],
                3,
                ["step", ["get", "symbolrank"], 11, 9, 10],
                6,
                ["step", ["get", "symbolrank"], 14, 9, 12, 12, 10],
                8,
                ["step", ["get", "symbolrank"], 16, 9, 14, 12, 12, 15, 10],
                13,
                ["step", ["get", "symbolrank"], 22, 9, 20, 12, 16, 15, 14]
              ],
              "text-radial-offset": [
                "step",
                ["zoom"],
                ["match", ["get", "capital"], 2, 0.6, 0.55],
                8,
                0
              ],
              "symbol-sort-key": ["get", "symbolrank"],
              "icon-image": "",
              "text-font": ["DIN Pro Regular", "Arial Unicode MS Regular"],
              "text-anchor": ["step", ["zoom"], "center", 8, "center"],
              "text-field": [
                "coalesce",
                ["get", "name_zh-Hans"],
                ["get", "name"]
              ],
              "text-max-width": 7
            },
            "paint": {
              "text-color": [
                "step",
                ["get", "symbolrank"],
                "hsl(0, 0%, 66%)",
                11,
                "hsl(0, 0%, 53%)",
                16,
                "hsl(0, 0%, 47%)"
              ],
              "text-halo-color": "hsl(0, 0%, 3%)",
              "text-halo-width": 1,
              "icon-opacity": ["step", ["zoom"], 1, 8, 0],
              "text-halo-blur": 1
            }
          },
          {
            "id": "settlement-major-label",
            "type": "symbol",
            "metadata": {
              "mapbox:featureComponent": "place-labels",
              "mapbox:group": "Place labels, place-labels"
            },
            "source": "composite",
            "source-layer": "place_label",
            "minzoom": 2,
            "maxzoom": 15,
            "filter": [
              "all",
              ["<=", ["get", "filterrank"], 2],
              [
                "match",
                ["get", "class"],
                ["settlement", "disputed_settlement"],
                ["match", ["get", "worldview"], ["all", "US"], true, false],
                false
              ],
              [
                "step",
                ["zoom"],
                false,
                2,
                ["<=", ["get", "symbolrank"], 6],
                4,
                ["<", ["get", "symbolrank"], 7],
                6,
                ["<", ["get", "symbolrank"], 8],
                7,
                ["<", ["get", "symbolrank"], 10],
                10,
                ["<", ["get", "symbolrank"], 11],
                11,
                ["<", ["get", "symbolrank"], 13],
                12,
                ["<", ["get", "symbolrank"], 15],
                13,
                [">=", ["get", "symbolrank"], 11],
                14,
                [">=", ["get", "symbolrank"], 15]
              ]
            ],
            "layout": {
              "text-line-height": 1.1,
              "text-size": [
                "interpolate",
                ["cubic-bezier", 0.2, 0, 0.9, 1],
                ["zoom"],
                3,
                ["step", ["get", "symbolrank"], 13, 6, 11],
                6,
                ["step", ["get", "symbolrank"], 18, 6, 16, 7, 14],
                8,
                ["step", ["get", "symbolrank"], 20, 9, 16, 10, 14],
                15,
                ["step", ["get", "symbolrank"], 24, 9, 20, 12, 16, 15, 14]
              ],
              "text-radial-offset": [
                "step",
                ["zoom"],
                ["match", ["get", "capital"], 2, 0.6, 0.55],
                8,
                0
              ],
              "symbol-sort-key": ["get", "symbolrank"],
              "icon-image": "",
              "text-font": ["DIN Pro Medium", "Arial Unicode MS Regular"],
              "text-anchor": ["step", ["zoom"], "center", 8, "center"],
              "text-field": [
                "coalesce",
                ["get", "name_zh-Hans"],
                ["get", "name"]
              ],
              "text-max-width": 7
            },
            "paint": {
              "text-color": [
                "step",
                ["get", "symbolrank"],
                "hsl(0, 0%, 66%)",
                11,
                "hsl(0, 0%, 53%)",
                16,
                "hsl(0, 0%, 47%)"
              ],
              "text-halo-color": "hsl(0, 0%, 3%)",
              "text-halo-width": 1,
              "icon-opacity": ["step", ["zoom"], 1, 8, 0],
              "text-halo-blur": 1
            }
          },
          {
            "id": "state-label",
            "type": "symbol",
            "metadata": {
              "mapbox:featureComponent": "place-labels",
              "mapbox:group": "Place labels, place-labels"
            },
            "source": "composite",
            "source-layer": "place_label",
            "minzoom": 3,
            "maxzoom": 9,
            "filter": [
              "match",
              ["get", "class"],
              ["state", "disputed_state"],
              ["match", ["get", "worldview"], ["all", "US"], true, false],
              false
            ],
            "layout": {
              "text-size": [
                "interpolate",
                ["cubic-bezier", 0.85, 0.7, 0.65, 1],
                ["zoom"],
                4,
                ["step", ["get", "symbolrank"], 9, 6, 8, 7, 7],
                9,
                ["step", ["get", "symbolrank"], 21, 6, 16, 7, 14]
              ],
              "text-transform": "uppercase",
              "text-font": ["DIN Pro Bold", "Arial Unicode MS Bold"],
              "text-field": [
                "coalesce",
                ["get", "name_zh-Hans"],
                ["get", "name"]
              ],
              "text-letter-spacing": 0.15,
              "text-max-width": 6
            },
            "paint": {
              "text-color": "hsl(0, 0%, 66%)",
              "text-halo-color": "hsl(0, 0%, 3%)",
              "text-halo-width": 1,
              "text-opacity": 0.5
            }
          },
          {
            "id": "country-label",
            "type": "symbol",
            "metadata": {
              "mapbox:featureComponent": "place-labels",
              "mapbox:group": "Place labels, place-labels"
            },
            "source": "composite",
            "source-layer": "place_label",
            "minzoom": 1,
            "maxzoom": 10,
            "filter": [
              "match",
              ["get", "class"],
              ["country", "disputed_country"],
              ["match", ["get", "worldview"], ["all", "US"], true, false],
              false
            ],
            "layout": {
              "icon-image": "",
              "text-field": [
                "coalesce",
                ["get", "name_zh-Hans"],
                ["get", "name"]
              ],
              "text-line-height": 1.1,
              "text-max-width": 6,
              "text-font": ["DIN Pro Medium", "Arial Unicode MS Regular"],
              "text-radial-offset": ["step", ["zoom"], 0.6, 8, 0],
              "text-size": [
                "interpolate",
                ["cubic-bezier", 0.2, 0, 0.7, 1],
                ["zoom"],
                1,
                ["step", ["get", "symbolrank"], 11, 4, 9, 5, 8],
                9,
                ["step", ["get", "symbolrank"], 22, 4, 19, 5, 17]
              ]
            },
            "paint": {
              "icon-opacity": [
                "step",
                ["zoom"],
                ["case", ["has", "text_anchor"], 1, 0],
                7,
                0
              ],
              "text-color": "hsl(0, 0%, 40%)",
              "text-halo-color": "hsl(0, 0%, 3%)",
              "text-halo-width": 1.25
            }
          },
          {
            "id": "continent-label",
            "type": "symbol",
            "metadata": {
              "mapbox:featureComponent": "place-labels",
              "mapbox:group": "Place labels, place-labels"
            },
            "source": "composite",
            "source-layer": "natural_label",
            "minzoom": 0.75,
            "maxzoom": 3,
            "filter": ["==", ["get", "class"], "continent"],
            "layout": {
              "text-field": [
                "coalesce",
                ["get", "name_zh-Hans"],
                ["get", "name"]
              ],
              "text-line-height": 1.1,
              "text-max-width": 6,
              "text-font": ["DIN Pro Medium", "Arial Unicode MS Regular"],
              "text-size": [
                "interpolate",
                ["exponential", 0.5],
                ["zoom"],
                0,
                10,
                2.5,
                15
              ],
              "text-transform": "uppercase",
              "text-letter-spacing": 0.05
            },
            "paint": {
              "text-color": "hsl(0, 0%, 40%)",
              "text-halo-color": "hsl(0, 0%, 3%)",
              "text-halo-width": 1.5,
              "text-opacity": [
                "interpolate",
                ["linear"],
                ["zoom"],
                0,
                0.8,
                1.5,
                0.5,
                2.5,
                0
              ]
            }
          }
        ],
        "created": "2023-02-24T03:55:18.006Z",
        "modified": "2023-04-26T02:25:37.566Z",
        "id": "clei02qtz002001pnqqsuall1",
        "owner": "2652202513",
        "visibility": "private",
        "protected": false,
        "draft": false
      },
      provinceProps: {
        lazy: true,
        checkStrictly: true,
        lazyLoad :(node, resolve)=> {
          // node 节点数据 node.value => 当前节点的值
          // level: 层级 => 1,2,3,4
          const { level } = node;
          // 动态节点
          const nodes = [];
          // 为1代表第一次请求
          let type = level == 0 ? "00" : node.value;
          GetAreaIdData(type).then((res)=>{
            res.data.map((item)=>{
              let obj = {
                value: item.code,
                label: item.name,
                leaf: node.level >= 2,
              };
              nodes.push(obj)
            });
            //节点返回
            resolve(nodes)
          })
        }
      },
      currentProvince:['45'],
      chinaJson:null,
      dialogVisible:false,
      dialogFirePointData:[],
      dialogAreaId:null,
      value2:['5'],
      reliabilityLists2:[{
        value: '1',
        label: '置信度1'
      }, {
        value: '2',
        label: '置信度2'
      }, {
        value: '3',
        label: '置信度3'
      }, {
        value: '4',
        label: '置信度4'
      }, {
        value: '5',
        label: '置信度5'
      }],
      dialogPage:1,
      dialogTotal:0,
      mapLoading:false,
      formLoading:false
    }
  },
  mounted() {
    mapBoxGl.accessToken = 'pk.eyJ1IjoiMjY1MjIwMjUxMyIsImEiOiJjbGVoMXJ2NmMwMm14M3dubHo4Nm1vNmY1In0.DfyxyI7qUMJX2SjpodZ5jw';
    this.getData()//页面初始化时获取分页火点数据
    this.getTotalData()
     GetAreaIdData('00').then(async (res)=>{
    await this.formatData(res.data)
      this.a()
    })
  },
  watch:{
    areaRangeActive(){
     if (this.areaRangeActive){
       const that = this
       setTimeout(function () {
         that.map = new mapBoxGl.Map({
           container: 'map',
           style: that.mapStyle,
           center: [106.128453,30.852687],
           projection:'mercator',
           zoom: 2.6,
         });
         that.hoveredPolygonId = null;
         const popup = new mapBoxGl.Popup({
           closeButton: false
         });
         that.map.on('load', () => {
           that.mapLoading = true
           reQuest({
             url:'https://forest-fire.oss-cn-hangzhou.aliyuncs.com/json/china.json',
           }).then(async (res) =>{
             that.chinaJson = res
             let _features = res.features;
             _features.forEach((_feature)=>{
               that.AreaTotal.forEach((_area)=>{
                 if(_feature.properties.adcode == _area.areaId){
                   _feature.properties['countValue'] = _area.countValue;
                 }
               })
             })
             await that.init(res,popup);
           });
         });
         },500)

     }
    }
},
  methods:{
    formatData(data){
        return new Promise(resolve => {
          data.forEach(a=>{
            this.province.push({
              value:a.code,
              label:a.name,
              children:[]
            })
          })

          resolve()
        })
    },
    a(){
      this.province.forEach(a=>{
        GetAreaIdData(a.value).then(res=>{
          res.data.forEach(b=>{
            a.children.push(
                {
                  value:b.code,
                  label:b.name,
                }
            )
          })
        })
      })
    },
    secondToDHMS,
    init(res,popup){
      if (this.map.getSource('states')){
        if (this.map.getLayer('states-fills')) this.map.removeLayer('states-fills');
        this.map.removeSource('states')
      }
      this.map.addSource('states', {
        'type': 'geojson',
        'data': res
      });
      this.map.addLayer({
        'id': 'states-fills',
        'type': 'fill',
        'source': 'states',
        'layout': {},
        'paint': {
          'fill-color': '#627BC1',
          'fill-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            1,
            0
          ]
        }
      });
      this.map.addLayer({
        'id': 'states-borders',
        'type': 'line',
        'source': 'states',
        'layout': {},
        'paint': {
          'line-color': '#627BC1',
          'line-width': 2
        }
      });
      this.mapLoading = false
      this.map.on('mousemove', 'states-fills', (e) => {
        if (e.features.length > 0) {
          if (this.hoveredPolygonId !== null) {
            this.map.setFeatureState(
                { source: 'states', id: this.hoveredPolygonId },
                { hover: false }
            );
          }
          this.hoveredPolygonId = e.features[0].id;
          this.map.setFeatureState(
              { source: 'states', id: this.hoveredPolygonId },
              { hover: true }
          );
          this.map.getCanvas().style.cursor = 'pointer';
          popup
              .setLngLat(e.lngLat)
              .setText(e.features[0].properties.name+'累计火点数： '+e.features[0].properties.countValue)
              .addTo(this.map);
        }
      });
      this.map.on('mouseleave', 'states-fills', () => {
        if (this.hoveredPolygonId !== null) {
          this.map.setFeatureState(
              { source: 'states', id: this.hoveredPolygonId },
              { hover: false }
          );
        }
        this.hoveredPolygonId = null;
        this.map.getCanvas().style.cursor = '';
        popup.remove()
      });
      this.map.on('click', 'states-fills', (e) => {
       this.dialogVisible =true
        this.dialogPage =1
        this.value2 = this.value1
        this.selectedTime2= this.selectedTime
        this.dialogAreaId = e.features[0].properties.adcode.toString().substring(0,2)
        reQuest({
          url:'/blade-overview/selectFirePointByGeoJson',
          params:{
            sourceType : 8,
            reliabilityList: this.value2.toString(),
            seekOutTimeStart:this.selectedTime2?this.selectedTime2[0]:'',
            seekOutTimeEnd: this.selectedTime2?this.selectedTime2[1]:'',
            areaId:this.dialogAreaId,
            size:8,
            current:this.dialogPage,
          }
        }).then(res=>{
          console.log(res)
          this.dialogFirePointData = res.data.records[0].features
          this.dialogFirePointData.forEach((feature)=>{
            feature.properties.durationTime=secondToDHMS(feature.properties.durationTime)
          })
          this.dialogTotal = res.data.total
        });
      });
    },
    getDialogData(){
      reQuest({
        url:'/blade-overview/selectFirePointByGeoJson',
        params:{
          sourceType : 8,
          reliabilityList: this.value2.toString(),
          seekOutTimeStart:this.selectedTime2?this.selectedTime2[0]:'',
          seekOutTimeEnd: this.selectedTime2?this.selectedTime2[1]:'',
          areaId:this.dialogAreaId,
          size:8,
          current:this.dialogPage,
        }
      }).then(res=>{
        this.dialogFirePointData = res.data.records[0].features
        this.dialogFirePointData.forEach((feature)=>{
          feature.properties.durationTime=secondToDHMS(feature.properties.durationTime)
        })
        this.dialogTotal = res.data.total
      });
    },
    getData(){
      if (this.timeRangeActive){
        this.formLoading=true
        console.log(Array.isArray(this.currentProvince)?this.currentProvince[this.currentProvince.length-1]:this.currentProvince)
        // GetFirePointPaginated({
        //   reliabilityList: this.value1.toString(),
        //   seekOutTimeStart:this.selectedTime?this.selectedTime[0]:'',
        //   seekOutTimeEnd: this.selectedTime?this.selectedTime[1]:'',
        //   areaId:Array.isArray(this.currentProvince)?this.currentProvince[this.currentProvince.length-1]:this.currentProvince},
        //     6,
        //     this.currentPage
        // )
        reQuest({
          url:'/blade-overview/selectFirePointByGeoJson',
          params:{
            reliabilityList: this.value1.toString(),
            seekOutTimeStart:this.selectedTime?this.selectedTime[0]:'',
            seekOutTimeEnd: this.selectedTime?this.selectedTime[1]:'',
            areaId:Array.isArray(this.currentProvince)?this.currentProvince[this.currentProvince.length-1]:this.currentProvince,
            size:6,
            current:this.currentPage,
          }
        }).then(res=>{
          this.formLoading=false
          this.firePointData = res.data.records[0]
          console.log(this.firePointData,this.currentProvince)
          this.total = res.data.total
        });
      }else if(this.areaRangeActive){
        this.mapLoading = true
        reQuest({
          url:'/blade-overview/countAreaFirePoint',
          params:{
            sourceType : 8,
            reliabilityList: this.value1.toString(),
            seekOutTimeStart:this.selectedTime[0],
            seekOutTimeEnd: this.selectedTime[1],
            areaType:0,
          }
        }).then(res=>{
          this.AreaTotal = res.data
          console.log(this.value1.toString(),res.data)
          let _features = this.chinaJson.features;
          _features.forEach((_feature)=>{
            this.AreaTotal.forEach((_area)=>{
              if (_feature.properties.adcode == _area.areaId){
                _feature.properties['countValue']= _area.countValue
              }
            })
          })
          upDateLayer(this.map,'states',this.chinaJson)
          this.mapLoading = false
        });
      }

    },getTotalData(){
      reQuest({
        url:'/blade-overview/countAreaFirePoint',
        params:{
          sourceType : 8,
          reliabilityList: this.value1.toString(),
          seekOutTimeStart:this.selectedTime[0],
          seekOutTimeEnd: this.selectedTime[1],
          areaType:0,
        }
      }).then(res=>{
        this.AreaTotal = res.data
      });
    },
    chinaRange(){
      if (!this.chinaRangeActive){
      this.chinaRangeActive=true
      this.worldRangeActive=false
      }
    },
    worldRange(){
      if (!this.worldRangeActive){
      this.worldRangeActive=true
      this.chinaRangeActive=false
      }
    },
    pageChange(newPage){
      this.currentPage=newPage
      this.getData()
    },
    dialogPageChange(newPage){
      this.dialogPage=newPage
      this.getDialogData()
    },
    ConditionalOptions(index){
      switch (index) {
        case 'time':
          if (!this.timeRangeActive) {
            this.timeRangeActive = true
            this.areaRangeActive = false
          }
          break
        case 'area':
          if (!this.areaRangeActive) {
            this.areaRangeActive = true
            this.timeRangeActive = false
          }
          break
      }
    },goToFirePoint(data){
      const a =this.$router.resolve({
        name: 'newMap',
        query:{
          lngLat:[parseFloat(data.geometry.coordinates[0]),parseFloat(data.geometry.coordinates[1])],
          seekOutTime:data.properties.seekOutTime,
          name:data.properties.firePointName,
          durationTime:data.properties.durationTime
        }
      });
      window.open(a.href,'_blank')
    }
  },

}
</script>

<style lang="scss" scoped>
::v-deep{
  .el-input__inner{
    background-color: rgba(12, 14, 18, 1);
    border: 1px solid rgba(70, 73, 80, 1);
    font-size: 13px;
    color:rgba(82, 83, 86, 1);
    height: 32px;
  }
  .el-range-input{
    background-color: transparent;
  }
  .el-tag{
    background-color:rgba(78, 81, 83, 1) ;
    border: 0 solid rgba(70, 73, 80, 0);
  }
  .el-pagination{
    margin-top: -25px;
    width: 440px;
    position: absolute;
    right: 20px;
  }
  .el-icon-time{
    line-height: 25px;
  }
  .el-dialog__title{
    color: white;
  }
  .el-dialog__header{
    background-color: rgba(47, 50, 56, 1);
    border: 1px solid rgba(129,150,182,0.5);
    border-radius: 4px 4px 0 0;
    overflow: hidden;
  }
  .el-dialog__body{
    padding: 10px 20px;
    background-color: rgba(47, 50, 56, 1);
    border: 1px solid rgba(129,150,182,0.5);
    border-radius: 0 0 4px 4px;
    overflow: hidden;
  }
  .el-dialog{
    background-color: transparent;
  }
}
#map{
  width: 44.7vw;
  height: 58.1vh;
  background-color: #212328;
  margin: 27px auto 0 auto;
}
.background{
  position : absolute;
  width : 100%;
  height : calc(100% - 48px);
  background-color: rgba(23, 24, 26, 1);
  img{
    width: 100%;
    height: 340px;
  }
  .main{
    width: 55vw;height: 770px;
    background-color: rgba(33, 35, 40, 1);
    position: absolute;
    top: 60px;left: 22.5vw;
    .RangeSelection{
      position: relative;
      top: 20px;
      left: 20px;
      width: 20.5vw;
      height: 32px;
    }
    .spacetimeSelection{
      position: absolute;
      top:20px;
      right: 20px;
      width: 10.7vw;
      height: 32px;
    }
    .timeSelectInput{
      margin: 35px 20px 0 20px;
      height: 32px;
      line-height: 32px;
      opacity: 1;
      font-size: 13px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .ButtonBox{
        flex: 2;
        display: flex;
        justify-content: end;
        .Button{
          width: 76px;
          height: 32px;
          margin-left: 10px;
          text-align: center;
          line-height: 32px;
          background-color: #fff;
          color: white;
          cursor: pointer;
        }
      }
    }
    .form{
      margin-left: 20px;
      margin-top: 4px;
      .item{
        margin-top: 16px;
        width: 52.8vw;
        height: 89px;
        background: #2D2D37;
        box-shadow: 2px 2px 12px 0 rgba(0,0,0,0.12);
        overflow: hidden;
        cursor: pointer;
        .text{
          float: left;
          color: rgba(175, 177, 179, 1);
          font-size: 12px;
          margin-top: 8px;
        }
      }
    }
    .total{
      font-size: 14px;
      line-height: 19px;
      color: rgba(255, 255, 255, 0.60);
      margin-bottom: 22px;
      margin-left: 40px;
    }
  }
.timeSelectInput{
  margin: 10px 20px 20px 20px;
  height: 32px;
  line-height: 32px;
  opacity: 1;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .ButtonBox{
    flex: 2;
    display: flex;
    justify-content: end;
    .Button{
      width: 76px;
      height: 32px;
      margin-left: 10px;
      text-align: center;
      line-height: 32px;
      background-color: #fff;
      color: white;
      cursor: pointer;
    }
  }
}
}
.Option{
  font-size: 14px;
  text-align: center;
  width: 50%;
  height:32px;
  color: rgba(224, 226, 229, 1);
  background-color: rgba(45, 47, 52, 1);
  float: left;
  line-height: 32px;
  cursor: pointer;
  user-select: none;
}
.Selected{
  background-color: rgba(78, 81, 83, 1);
}
.total{
  font-size: 14px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.60);
  margin-top: 10px;
  margin-left: 40px;
}
</style>