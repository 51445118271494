<template>
  <div class="news" id="news">
    <div class="newsHead">
      <table>
        <tr>
          <td style="width: 145px">观测时间</td>
          <td style="width: 145px">火点位置</td>
          <td style="width: 80px">持续时间</td>
          <td style="width: 80px">置信度</td>
        </tr>
      </table>

      <ul class="list" id="new" @mouseover="StopTimer" @mouseleave="startTimer">
        <li
            v-for="(item, index) in yjList"
            :key="item.id"
            :class="!index && play ? 'toUp' : ''"
            style="display: flex"
            @click="flyToFirePont(item)" title="点击定位火点">
            <span class="newsSpan" style="width: 147px">{{item.properties.seekOutTime}}</span>
            <span class="newsSpan" style="width: 147px">{{item.properties.firePointName}}</span>
            <span class="newsSpan" style="width: 90px">{{secondToDHMS(item.properties.durationTime)}}分钟</span>
            <span class="newsSpan" style="width: 70px">{{item.properties.reliability}}</span>
        </li>
      </ul>
    </div>

    <button class="moreExample" @click="open">
      <span>收起</span>
      <img alt="展开关闭图标" src="@/assets/img/newsSwitch.png" style="transform: rotateX(180deg)">
    </button>
    <div class="toUp" style="display: none">消除css警告</div>
  </div>

</template>

<script>
import {secondToDHMS} from "@/tuil/commonTool";
export default {
  name: "newsFirePointOpen",
  props: {//从父组件传入值
    yjList: {

    },
    map:null
  },
  data(){return{//初始值
    newMore:false,
    ulList: [],
    play: false,
    setIntervalData: null,
    currSeconds: 0,
    firePointDataLength4:false,
    firePointDataLength3:false,
    firePointDataLength2:false,
    firePointDataLength1:false,
  }
  },
  mounted(){
    if (this.yjList.length>5){
      this.setIntervalData = setInterval(this.startPlay, 2000);
    }
     if (this.yjList.length <5 && this.yjList.length >3){
      document.getElementById('news').style.height='250px'
    }else if (this.yjList.length <4 && this.yjList.length >2){
      document.getElementById('news').style.height='220px'
    }else if (this.yjList.length <3 && this.yjList.length >1){
      document.getElementById('news').style.height='180px'
    }else if ( this.yjList.length <2){
      document.getElementById('news').style.height='150px'
    }
  },
  methods:{
    secondToDHMS,
//函数方法
    startPlay() {
      let that = this;
      if (that.yjList.length >5){
      that.play = true; //开始播放
      setTimeout(() => {
        that.yjList.push(that.yjList[0]); //将第一条数据塞到最后一个
        that.yjList.shift(); //删除第一条数据
        that.play = false; //暂停播放,此处修改，保证每一次都会有动画显示
      }, 500)
      }
    },
    startTimer() {
      this.setIntervalData = setInterval(this.startPlay, 2000);
    },
    StopTimer() {

      clearInterval(this.setIntervalData)

    },
    flyToFirePont(item){
      this.map.flyTo({
        center: item.geometry.coordinates,
        zoom: 12,
        essential: true
      })
    },
    open(){
      clearInterval(this.setIntervalData)
      this.$emit('Open')
    }
  },
  computed:{//计算属性

  },
  watch: {//状态监听  元素中写入v-model="变量名"

  },
  filters:{//过滤器

  }
}
</script>

<style scoped>
.news{
  position: absolute;
  z-index: 2;
  top: 30px;
  right: 28px;
  width: 456px;
  height: 300px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.46);
  border:1px solid rgba(170, 202, 255, 0.3);
  -webkit-backdrop-filter: blur(10px);
  user-select: none;
}
.moreExample{
  position: absolute;
  font-size: 14px;
  right: 10px;
  bottom: 10px;
  width: 441px;
  height: 40px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.08);
  border:1px solid rgba(170, 202, 255, 0.3);
  -webkit-backdrop-filter: blur(12px);
  cursor: pointer;
  color: rgba(146, 189, 255, 1);
}
.newsHead{
  width: 456px;
  height: 35px;
  position: absolute;
  background: linear-gradient( rgba(47, 61, 82, 0.09),rgba(28, 59, 104, 1));
  color: white;
}
.newsHead td{
  width: 104px;
  font-size: 14px;
  text-align: center;
  height: 35px;
  line-height: 35px;
}
.moreExample img{
  width: 16px;
  height: 16px;
  position: relative;
  top:2px
}
.toUp {
  margin-top: -40px;
  transition: all 0.5s;
}

.list {
  list-style: none;
  text-align: left;
  overflow: hidden;
  height: 200px;
  font-size: 17px;
  margin: 0;
  padding: 10px 0 0 0;
}
li {
  text-align: left;
  height: 40px;
  line-height: 49px;
  font-weight: 600;
  cursor: pointer;
}
.newsSpan{
  display: inline-block;
  overflow: hidden;
  text-align: center;
  width: 108px;
  font-size: 14px;
  height: 35px;
  line-height: 35px;
}
@media screen and (max-width: 1155px) {
  .news{
    position: absolute;
    z-index: 2;
    top: 100px;
    right: 28px;
    width: 589px;
    height: 300px;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.46);
    border:1px solid rgba(170, 202, 255, 0.3);
    -webkit-backdrop-filter: blur(10px);
    user-select: none;
  }
}
</style>