/****
 * 秒转天时分秒 因业务需求删除秒  后续可添加 + seconds+"秒"
 *@params second 秒
 * @return duration 天时分秒时间
 */
export const secondToDHMS=(second)=>{
    let duration
    let days = Math.floor(second / 86400);
    let hours = Math.floor((second % 86400) / 3600);
    let minutes = Math.floor(((second % 86400) % 3600) / 60);
    let seconds = Math.floor(((second % 86400) % 3600) % 60);
    days>0?  duration = days + "天" + hours + "小时" + minutes + "分" :
        hours>0?  duration = hours + "小时" + minutes + "分" :
            minutes>0? duration = minutes + "分" :
                seconds>0? duration = seconds + "秒":null
    return duration;
}