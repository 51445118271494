<template>
  <div id="app">
    <head-bar/>
    <router-view />
  </div>
</template>

<script>
import headBar from "./components/headBar.vue";
export default {
  name: 'App',
  components: {
    headBar
  },data(){
    return{
    }
  },
  methods:{
  }
}
</script>

<style>
#app {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
</style>
