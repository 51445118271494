<template>
<div class="headBar">
  <div class="title" @click="$router.push({path: '/'});">
    <div class="title-img"></div>
  </div>
  <div class="nav">
    <div class="Options" style="text-decoration-line: line-through"></div>
    <router-link to="/HistoricalFirePoint" class="Options">历史火点</router-link>
    <div class="Options" style="text-decoration-line: line-through"></div>
    <div class="Options" style="text-decoration-line: line-through"></div>
  </div>
</div>
</template>

<script>
export default {
  name: "headBar",
  data() {
    return {
      isHistoricalFirePoint:true
    };
  },
  methods:{
  }
}

</script>

<style lang="scss" scoped>
.headBar{
  width: 100%;height: 48px;
  background-color: #2F3238;
  .nav{
    float: right;
    .Options{
      display: inline-block;
      margin-right: 56px;
      margin-top: 15px;
      font-size: 14px;
      color: #D6D8DB ;
      cursor: pointer;
      text-decoration:none;
    }
  }
  .title {
    position: absolute;
    cursor: pointer;
  }

  .title-img {
    color: white;
    font-size: 20px;
    line-height: 48px;
    width: 136px;
    height: 42px;
    position: relative;
    top: 4px;
    background-image: url("@/assets/img/img.png");
    background-size: 100% 100%;
    left: 30px;
    background-repeat: no-repeat;
  }
}
</style>