<template>
  <div id="map" v-loading="firePointDataLoading">
    <div class="dataTime" :style="cssVars">
      <div>卫星火点 {{
          firePointData.features[0].properties
              .createTime
        }}
      </div>
    </div>
    <el-select v-model="value1" multiple placeholder="请选择置信度" style="z-index: 999 ;" @change="reliabilityChange" :style="cssVars">
      <el-option
          v-for="item in reliabilityList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
      >
      </el-option>
    </el-select>
<!--    <div class="timeSelect" :style="cssVars">-->
<!--      <div class="timeSelectInput">-->
<!--        <el-date-picker-->
<!--            v-model=" selectedTime"-->
<!--            type="datetimerange"-->
<!--            start-placeholder="开始日期"-->
<!--            end-placeholder="结束日期"-->
<!--            :default-time="['12:00:00']"-->
<!--            value-format="timestamp"-->
<!--        >-->
<!--        </el-date-picker>-->
<!--        <div v-if="!isBrowseHistory" class="playButton" @click="play">查询</div>-->
<!--        <div v-else class="playButton" @click="stop">重置</div>-->
<!--      </div>-->

<!--    </div>-->
    <news :yj-list="this.newsData" :map="this.map" v-if="this.newIsShow" @Open="newsIsOpen"></news>
    <newsOpen :yj-list="this.newsData" :map="this.map" v-if="!this.newIsShow" @Open="newsIsOpen"></newsOpen>
    <div class="tool_menu">
      <div class="tool" :class="{tool_active:rangingActive}" @click="rangingClick">
        <div class="ranging"></div>
        <div class="tool_span">测距</div>
      </div>
      <div class="tool" :class="{tool_active:measuredActive}" @click="measuredAreaClick">
        <div class="measuredArea"></div>
        <div class="tool_span">测面积</div>
      </div>
      <div class="tool" :class="{tool_active:measuredActive}" @click="switchLayer">
        <div class="earth"></div>
        <div class="tool_span">切换底图</div>
      </div>
    </div>
    <div id="Area" v-if="measuredActive">
    </div>
  </div>
</template>

<script>
import {intiMap} from "@/tuil/drawMapBox";
import news from "@/components/news.vue";
import newsOpen from "@/components/newsOpen.vue";
import {addFirePoint, changeFlag} from "@/tuil/mapTool";
import mapBoxGL from "mapbox-gl";
import * as turf from "@turf/turf";
import MapboxDraw from "@mapbox/mapbox-gl-draw"
import {reQuest} from "@/request/axios";
import {GetFirePoint, GetFirePointPaginated} from "@/api/firePoint"

export default {
  name: 'starVisionMap',
  components: {
    news,
    newsOpen
  },
  props: {//从父组件传入值

  },
  data() {
    return {//初始值
      map: null,
      H09WLFActive: true,
      rangingActive: false,
      measuredActive: false,
      firePointData: {
        "type": "FeatureCollection",
        "name": "firePoint",
        "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
        "features": [
          {
            "type": "Feature",
            "properties": {
              "id": 1,
              "createTime": "2023-4-28 1:00",
              "seekOutTime": "2023-4-28 1:00",
              "firePointName": "日本东京江户川区",
              "forestFireIntensity": 7,
              "forestFireIntensityT": 8,
              "hotspotNum": 1
            },
            "geometry": {"type": "Point", "coordinates": [139.88618875317758, 35.72114009456659]}
          },
          {
            "type": "Feature",
            "properties": {
              "id": 1,
              "createTime": "2023-4-25 1:10",
              "seekOutTime": "2023-4-28 1:00",
              "firePointName": "日本大阪西淀川区",
              "forestFireIntensity": 8,
              "forestFireIntensityT": 6,
              "hotspotNum": 2
            },
            "geometry": {"type": "Point", "coordinates": [135.4298305001684, 34.69253125523217]}
          },
          {
            "type": "Feature",
            "properties": {
              "id": 1,
              "createTime": "2023-4-25 1:20",
              "seekOutTime": "2023-4-28 1:00",
              "firePointName": "日本仙台市太白山",
              "forestFireIntensity": 12,
              "forestFireIntensityT": 14,
              "hotspotNum": 3
            },
            "geometry": {"type": "Point", "coordinates": [140.79964075252758, 38.233071821430286]}
          },
          {
            "type": "Feature",
            "properties": {
              "id": 1,
              "createTime": "2023-4-25 1:30",
              "seekOutTime": "2023-4-28 1:00",
              "firePointName": "日本对马岛月轮山",
              "forestFireIntensity": 31,
              "forestFireIntensityT": 55,
              "hotspotNum": 7
            },
            "geometry": {"type": "Point", "coordinates": [129.24457845380266, 34.192387108477945]}
          },
          {
            "type": "Feature",
            "properties": {
              "id": 1,
              "createTime": "2023-4-25 1:40",
              "seekOutTime": "2023-4-28 1:00",
              "firePointName": "日本四国松山市松前町",
              "forestFireIntensity": 24,
              "forestFireIntensityT": 12,
              "hotspotNum": 3
            },
            "geometry": {"type": "Point", "coordinates": [132.70362755074638, 33.783852533396015]}
          },
          {
            "type": "Feature",
            "properties": {
              "id": 1,
              "createTime": "2023-4-25 1:50",
              "seekOutTime": "2023-4-28 1:00",
              "firePointName": "台湾宜兰市大同乡",
              "forestFireIntensity": 4,
              "forestFireIntensityT": 3,
              "hotspotNum": 1
            },
            "geometry": {"type": "Point", "coordinates": [121.60195465375921, 24.670307469118598]}
          }
        ]
      },
      initData: {
        clickMeasurePointsFunction: null,
        mapClickFunction: null,
        mapMousemoveFunction: null,
        mapDblClickFunction: null,
        map: null,
        isMeasure: true,
        jsonPoint: {'type': 'FeatureCollection', 'features': []},
        jsonLine: {'type': 'FeatureCollection', 'features': []},
        points: []
      },
      before: null,
      after: null,
      popup: null,
      newIsShow: true,
      reliabilityList: [{
        value: '1',
        label: '置信度1'
      }, {
        value: '2',
        label: '置信度2'
      }, {
        value: '3',
        label: '置信度3'
      }, {
        value: '4',
        label: '置信度4'
      }, {
        value: '5',
        label: '置信度5'
      }],
      value1: ['5'],
      selectedTime: '',
      isBrowseHistory: false,
      newsData: [],
      currentPage: 1,
      isLoaded: false,
      newsDataRequest:null,
      firePointDataLoading:false,
      isGreenLayer:false,
      markers:{},
      markersOnScreen:{}
    }
  },
  mounted() {
    this.initMap()
    changeFlag(true)
    this.map.on('load', () => {
      if(this.$route.query.lng){
        this.map.flyTo({
          center: [this.$route.query.lng, this.$route.query.lat],
          zoom: 14,
        })
      }
        this.getNewsDataAuto()
        this.AutoGetFirePointData()
      setInterval(this.AutoGetFirePointData, 5000 * 60)
    })

  },
  methods: {//函数方法
    switchLayer(){
      this.isGreenLayer = !this.isGreenLayer
      this.isGreenLayer?
      this.map.setLayoutProperty('gd-satellite', 'visibility', 'visible'):
          this.map.setLayoutProperty('gd-satellite', 'visibility', 'none')
    },
    initMap() {
      this.map = intiMap('map');
      this.draw = new MapboxDraw({
        keybindings: false
      });
      this.map.addControl(this.draw, 'top-left');
      this.map.on('draw.render', this.updateArea);
    },
    play() {
      this.isBrowseHistory = !this.isBrowseHistory
      delete this.areaId
      this.getFirePointData()
      // 播放和暂停逻辑
    },
    stop() {
      this.isBrowseHistory = !this.isBrowseHistory
      delete this.areaId
      this.selectedTime = ''
      this.value1=['5']
      this.AutoGetFirePointData()
      // 播放和暂停逻辑
    },
    reliabilityChange() {
      console.log(this.isBrowseHistory)
      this.isBrowseHistory ? this.getFirePointData() : this.AutoGetFirePointData()
    },
    rangingClick() {
      if (!this.rangingActive) {
        this.measureLineLength(this.map)
        this.rangingActive = !this.rangingActive
      } else {
        this.rangingActive = !this.rangingActive
        this.closeMeasureLine(this.map)
      }
    },
    measuredAreaClick() {
      if (!this.measuredActive) {
        this.measuredActive = !this.measuredActive
        this.draw.changeMode("draw_polygon")
        this.map.on('dblclick', () => {
          this.draw.changeMode("draw_polygon")
        })
      } else {
        this.measuredActive = !this.measuredActive
        let selectedFeatures = this.draw.getAll()
        if (selectedFeatures.features.length > 0) {
          for (let i = 0; i < selectedFeatures.features.length; i++) {
            this.draw.delete(selectedFeatures.features[i].id)
          }
        }
        this.draw.changeMode("simple_select")
      }
    },
    getFirePointData() {
      this.isLoaded = false
      this.currentPage = 1
      this.newsData = []
      this.getNewsData()
      this.firePointDataLoading=true
      this.markers={}
      this.markersOnScreen={}
      GetFirePoint({
        reliabilityList: this.value1.toString(),
        seekOutTimeStart: this.selectedTime[0],
        seekOutTimeEnd: this.selectedTime[1],
        areaId: this.areaId ? this.areaId : null
      }).then(res => {
        this.firePointData = res.data
        addFirePoint(this.map, this.firePointData, 'H09WLFPoint',this.markers,this.markersOnScreen)
        this.firePointDataLoading = false
      });
    },
    AutoGetFirePointData() {
      if (!this.isBrowseHistory && this.$route.path === '/') {
        console.log('开始更新最新数据')
        this.newsData = []
        this.currentPage = 1
        this.isLoaded = false
        this.getNewsDataAuto()
        this.firePointDataLoading=true
        this.markers={}
        this.markersOnScreen={}
        GetFirePoint({
          reliabilityList: this.value1.toString()
        }).then(res => {
          this.firePointData = res.data
          addFirePoint(this.map, this.firePointData, 'H09WLFPoint',this.markers,this.markersOnScreen)
          this.firePointDataLoading=false
        });
      }
    },
    measureLineLength(mapObject) {
      let that = this
      const currentMapContainerId = mapObject._container.getAttribute('id')
      currentMapContainerId.indexOf('-after') !== -1 ? this.after = JSON.parse(JSON.stringify(this.initData)) : this.before = JSON.parse(JSON.stringify(this.initData))
      const resultData = this.getBeforeOrAfterDataByMapContainerIdHandler(mapObject)
      resultData.isMeasure = true
      resultData.map = mapObject
      resultData.map.doubleClickZoom.disable() // 禁止双击缩放
      resultData.map.getCanvas().style.cursor = 'default'
      this.clearMeasureLine(mapObject)
      resultData.jsonPoint = {'type': 'FeatureCollection', 'features': []}
      resultData.jsonLine = {'type': 'FeatureCollection', 'features': []}
      resultData.points = []
      const {mouseLabel, ele} = this.createMeasureLineLabelMarkerHandler(mapObject)
      this.createMeasureLinePLLHandler(resultData.jsonPoint, resultData.jsonLine, mapObject)

      let timer = null

      function mapClickHandler(_e) {

        timer = setTimeout(() => {
          clearTimeout(timer)
          if (resultData.isMeasure) {
            const coords = [_e.lngLat.lng, _e.lngLat.lat]
            that.addMeasureLineRes(resultData.points, resultData.jsonPoint, coords, mapObject)
            that.addMeasureLinePoint(resultData.jsonPoint, resultData.jsonLine, coords, mapObject)
            resultData.points.push(coords)
          }
        }, 100)
      }

      resultData.mapClickFunction = mapClickHandler
      resultData.map.off('click', mapClickHandler)
      resultData.map.on('click', mapClickHandler)

      function mousemoveHandler(_e) {
        if (resultData.isMeasure) {
          const coords = [_e.lngLat.lng, _e.lngLat.lat]
          if (resultData.jsonPoint.features.length > 0) {
            const prev = resultData.jsonPoint.features[resultData.jsonPoint.features.length - 1]
            const json = {
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: [prev.geometry.coordinates, coords]
              }
            }
            resultData.map.getSource('measure-line-move').setData(json)
            let resultText = '起点'
            if (resultData.points.length !== 0) {
              const prev = resultData.jsonPoint.features[resultData.jsonPoint.features.length - 1]
              const prevCoordinates = prev ? prev.geometry.coordinates : ''
              const resultAngle = prevCoordinates ? that.getAngleHandle(prevCoordinates[0], prevCoordinates[1], coords[0], coords[1]).toFixed(1) + '°' : ''
              resultText = `<span>${that.getMeasureLineLength(resultData.points, coords)} / ${resultAngle}</span><span>单击确定地点，双击结束</span>`
            }
            ele.innerHTML = resultText
          } else {
            ele.style.display = 'block'
            ele.innerHTML = '点击地图开始测量'
          }
          mouseLabel.setLngLat(coords)
        }
      }

      resultData.mapMousemoveFunction = mousemoveHandler
      resultData.map.off('mousemove', mousemoveHandler)
      resultData.map.on('mousemove', mousemoveHandler)

      function dblclickHandler(_e) {
        that.rangingActive = false
        if (timer) {
          clearTimeout(timer)
        }
        if (resultData.isMeasure) {
          const coords = [_e.lngLat.lng, _e.lngLat.lat]
          that.addMeasureLinePoint(resultData.jsonPoint, resultData.jsonLine, coords, mapObject)
          resultData.isMeasure = false
          resultData.map.getCanvas().style.cursor = ''
          mouseLabel.remove()
          // 当前已关闭点击测距，所以清除move的线段
          resultData.map.getSource('measure-line-move').setData({'type': 'FeatureCollection', 'features': []})
          // 鼠标移入当前测距的点位，动态显示tips提示语
          resultData.map.on('mouseover', 'measure-line-points', (event) => {
            if (resultData.isMeasure) {
              return
            }
            resultData.map.getCanvas().style.cursor = 'pointer'
            const tipsCoords = [event.lngLat.lng, event.lngLat.lat]
            const tipsEle = document.createElement('div')
            tipsEle.setAttribute('class', 'measure-line-result delete-tips')
            tipsEle.innerHTML = '单击可删除此点'
            const tipsOption = {
              element: tipsEle,
              anchor: 'left',
              offset: [10, 20]
            }
            // eslint-disable-next-line no-undef
            new mapBoxGL.Marker(tipsOption)
                .setLngLat(tipsCoords)
                .addTo(resultData.map)
          })
          // 鼠标移出当前测距的点位，移除tips提示语
          resultData.map.on('mouseout', 'measure-line-points', () => {
            if (resultData.isMeasure) {
              resultData.map.getCanvas().style.cursor = 'default'
            } else {
              resultData.map.getCanvas().style.cursor = ''
            }
            const deleteTips = resultData.map._container.querySelector('.delete-tips')
            if (deleteTips) {
              deleteTips.remove()
            }
          })

          /**
           * 点击测距点位触发
           * @param event
           * */
          // eslint-disable-next-line no-undef,no-inner-declarations
          function clickMeasurePointsHandler(event) {
            if (resultData.jsonPoint.features.length > 2) {
              const features = resultData.map.queryRenderedFeatures(event.point)
              if (features.length > 0) {
                const measureResult = resultData.map._container.querySelectorAll('.measure-line-result')
                if (measureResult && measureResult.length > 0) {
                  Array.from(measureResult).forEach((m) => {
                    m.remove()
                  })
                }
                const {index} = features[0].properties
                resultData.jsonPoint.features = resultData.jsonPoint.features.filter(feature => feature.properties.index !== index)
                resultData.jsonLine.features = []
                const featuresArr = [...resultData.jsonPoint.features]
                const resultPoints = []
                featuresArr.forEach((feature, index) => {
                  const nextIndex = index + 1
                  if (featuresArr[nextIndex]) {
                    const current = featuresArr[index]
                    const next = featuresArr[nextIndex]
                    resultData.jsonLine.features.push({
                      type: 'Feature',
                      geometry: {
                        type: 'LineString',
                        coordinates: [current.geometry.coordinates, next.geometry.coordinates]
                      }
                    })
                  }
                  resultPoints.push(feature.geometry.coordinates)
                  const ele = document.createElement('div')
                  ele.setAttribute('class', 'measure-line-result')
                  if (index === 0) {
                    ele.innerHTML = '起点'
                  } else {
                    const prevIndex = index - 1
                    const prevCoordinates = featuresArr[prevIndex].geometry.coordinates
                    const currentCoordinates = feature.geometry.coordinates
                    const resultAngle = prevCoordinates ? that.getAngleHandle(prevCoordinates[0], prevCoordinates[1], currentCoordinates[0], currentCoordinates[1]).toFixed(1) + '°' : ''
                    ele.innerHTML = `${that.getMetersHandler(resultPoints, currentCoordinates)} / ${resultAngle}`
                  }
                  if (nextIndex === featuresArr.length) { // 终点需要再加一个marker
                    // 添加关闭按钮
                    that.createCloseMarkerHandler(clickMeasurePointsHandler, feature.geometry.coordinates, mapObject)
                  }
                  const left = window.document.documentElement.clientWidth > 7000 ? 20 : 8
                  const option = {
                    element: ele,
                    anchor: 'left',
                    offset: [left, 0]
                  }
                  // eslint-disable-next-line no-undef
                  new mapBoxGL.Marker(option)
                      .setLngLat(feature.geometry.coordinates)
                      .addTo(resultData.map)
                })
                resultData.map.getSource('measure-line-points').setData(resultData.jsonPoint)
                resultData.map.getSource('measure-line').setData(resultData.jsonLine)
                resultData.map.getSource('measure-line-move').setData({'type': 'FeatureCollection', 'features': []})
              }
            } else {
              that.closeMeasureLine(mapObject)
            }
          }

          resultData.clickMeasurePointsFunction = clickMeasurePointsHandler
          resultData.map.off('click', 'measure-line-points', clickMeasurePointsHandler)
          resultData.map.on('click', 'measure-line-points', clickMeasurePointsHandler)
          // 添加关闭按钮
          that.createCloseMarkerHandler(clickMeasurePointsHandler, coords, mapObject)
        }
      }

      resultData.mapDblClickFunction = dblclickHandler
      resultData.map.off('dblclick', dblclickHandler)
      this.map.doubleClickZoom.disable();
      resultData.map.on('dblclick', dblclickHandler)
    },
    clearMeasureLine(mapObject) {
      const resultData = this.getBeforeOrAfterDataByMapContainerIdHandler(mapObject)
      const measureResult = resultData.map._container.querySelectorAll('.measure-line-result')
      if (measureResult && measureResult.length > 0) {
        Array.from(measureResult).forEach((m) => {
          m.remove()
        })
      }
      const source = resultData.map.getSource('measure-line-points')
      const json = {
        'type': 'FeatureCollection',
        'features': []
      }
      if (source) {
        resultData.map.getSource('measure-line-points').setData(json)
        resultData.map.getSource('measure-line-move').setData(json)
        resultData.map.getSource('measure-line').setData(json)
      }
    },
    createMeasureLineLabelMarkerHandler(mapObject) {
      const resultData = this.getBeforeOrAfterDataByMapContainerIdHandler(mapObject)
      const ele = document.createElement('div')
      ele.style.display = 'none'
      ele.setAttribute('class', 'measure-line-result')
      const windowW = document.documentElement.clientWidth
      const top = windowW > 7000 ? 120 : 44
      const left = window.document.documentElement.clientWidth > 7000 ? 20 : 8
      const option = {
        element: ele,
        anchor: 'left',
        offset: [left, top]
      }
      // eslint-disable-next-line no-undef
      const mouseLabel = new mapBoxGL.Marker(option)
          .setLngLat([0, 0])
          .addTo(resultData.map)
      return {
        mouseLabel,
        ele
      }
    },
    createMeasureLinePLLHandler(jsonPoint, jsonLine, mapObject) {
      const resultData = this.getBeforeOrAfterDataByMapContainerIdHandler(mapObject)
      const source = resultData.map.getSource('measure-line-points')
      if (source) {
        resultData.map.getSource('measure-line-points').setData(jsonPoint)
        resultData.map.getSource('measure-line-move').setData(jsonLine)
        resultData.map.getSource('measure-line').setData(jsonLine)
      } else {
        resultData.map.addSource('measure-line-points', {
          type: 'geojson',
          data: jsonPoint
        })
        resultData.map.addSource('measure-line', {
          type: 'geojson',
          data: jsonLine
        })
        resultData.map.addSource('measure-line-move', {
          type: 'geojson',
          data: jsonLine
        })
        const windowW = document.documentElement.clientWidth
        const LW = windowW > 7000 ? 6 : 2
        const RW = windowW > 7000 ? 10.5 : 3.5
        const CW = windowW > 7000 ? 7.5 : 2.5
        resultData.map.addLayer({
          id: 'measure-line-move',
          type: 'line',
          source: 'measure-line-move',
          paint: {
            'line-color': 'white',
            'line-width': LW,
            'line-opacity': 0.5
          }
        })
        resultData.map.addLayer({
          id: 'measure-line',
          type: 'line',
          source: 'measure-line',
          paint: {
            'line-color': 'white',
            'line-width': LW,
            'line-opacity': 1
          }
        })
        resultData.map.addLayer({
          id: 'measure-line-points',
          type: 'circle',
          source: 'measure-line-points',
          paint: {
            'circle-color': '#ffffff',
            'circle-radius': RW,
            'circle-stroke-width': CW,
            'circle-stroke-color': 'black'
          }
        })
      }
      this.moveLayerHandler(resultData.map)
    },
    moveLayerHandler(mapObject) {
      const {map} = this.getBeforeOrAfterDataByMapContainerIdHandler(mapObject)
      const {layers} = map.getStyle()
      const length = layers.length
      const lastLayerId = map.getStyle().layers[length - 1].id
      if (lastLayerId !== 'measure-line-points') {
        map.moveLayer('measure-line-points', map.getStyle().layers[length - 1].id)
        map.moveLayer('measure-line', 'measure-line-points')
        map.moveLayer('measure-line-move', 'measure-line')
      }
    },
    addMeasureLinePoint(jsonPoint, jsonLine, coords, mapObject) {
      const {map} = this.getBeforeOrAfterDataByMapContainerIdHandler(mapObject)
      if (jsonPoint.features.length > 0) {
        const prev = jsonPoint.features[jsonPoint.features.length - 1]
        jsonLine.features.push({
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: [prev.geometry.coordinates, coords]
          }
        })
        map.getSource('measure-line').setData(jsonLine)
      }
      jsonPoint.features.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: coords
        },
        properties: {
          index: jsonPoint.features.length
        }
      })
      // 重新整理point点位，若是重复点位则不添加
      const data = [...jsonPoint.features]
      const result = []
      data.forEach((feature, index) => {
        if (data[index - 1]) {
          if (data[index - 1].geometry.coordinates[0] !== feature.geometry.coordinates[0] || data[index - 1].geometry.coordinates[1] !== feature.geometry.coordinates[1]) {
            result.push(feature)
          }
        } else {
          result.push(feature)
        }
      })
      jsonPoint.features = [...result]
      map.getSource('measure-line-points').setData(jsonPoint)
    },
    getMeasureLineLength(points, coords) {
      const _points = points.concat([coords])
      // eslint-disable-next-line no-undef
      const line = turf.lineString(_points)
      // eslint-disable-next-line no-undef
      let len = turf.length(line)
      if (len < 1) {
        len = Math.round(len * 1000) + '米'
      } else {
        len = len.toFixed(2) + '公里'
      }
      return len
    },
    addMeasureLineRes(points, jsonPoint, coords, mapObject) {
      let that = this
      const {map} = this.getBeforeOrAfterDataByMapContainerIdHandler(mapObject)
      const ele = document.createElement('div')
      ele.style.color = "white"
      ele.setAttribute('class', 'measure-line-result')
      const left = window.document.documentElement.clientWidth > 7000 ? 20 : 8
      const option = {
        element: ele,
        anchor: 'left',
        offset: [left, 0]
      }
      let resultText = '起点'
      if (points.length !== 0) {
        const prev = jsonPoint.features[jsonPoint.features.length - 1]
        const prevCoordinates = prev ? prev.geometry.coordinates : ''
        const resultAngle = prevCoordinates ? this.getAngleHandle(prevCoordinates[0], prevCoordinates[1], coords[0], coords[1]).toFixed(1) + '°' : ''
        resultText = `${that.getMeasureLineLength(points, coords)} / ${resultAngle}`
      }
      ele.innerHTML = resultText
      // eslint-disable-next-line no-undef
      new mapBoxGL.Marker(option)
          .setLngLat(coords)
          .addTo(map)
    },
    getMetersHandler(resultPoints) {
      if (resultPoints.length > 1) {
        const _points = [...resultPoints]
        // eslint-disable-next-line no-undef
        const line = turf.lineString(_points)
        // eslint-disable-next-line no-undef
        let len = turf.length(line)
        if (len < 1) {
          len = Math.round(len * 1000) + '米'
        } else {
          len = len.toFixed(2) + '公里'
        }
        return len
      }
    },
    getAngleHandle(lng1, lat1, lng2, lat2) {
      const a = (90 - lat2) * Math.PI / 180
      const b = (90 - lat1) * Math.PI / 180
      const AOC_BOC = (lng2 - lng1) * Math.PI / 180
      const cosC = Math.cos(a) * Math.cos(b) + Math.sin(a) * Math.sin(b) * Math.cos(AOC_BOC)
      const sinC = Math.sqrt(1 - cosC * cosC)
      const sinA = Math.sin(a) * Math.sin(AOC_BOC) / sinC
      const A = Math.asin(sinA) * 180 / Math.PI
      let res = 0
      if (lng2 > lng1 && lat2 > lat1) {
        res = A
      } else if (lng2 > lng1 && lat2 < lat1) {
        res = 180 - A
      } else if (lng2 < lng1 && lat2 < lat1) {
        res = 180 - A
      } else if (lng2 < lng1 && lat2 > lat1) {
        res = 360 + A
      } else if (lng2 > lng1 && lat2 === lat1) {
        res = 90
      } else if (lng2 < lng1 && lat2 === lat1) {
        res = 270
      } else if (lng2 === lng1 && lat2 > lat1) {
        res = 0
      } else if (lng2 === lng1 && lat2 < lat1) {
        res = 180
      }
      return res
    },
    createCloseMarkerHandler(clickMeasurePointsHandler, coords, mapObject) {
      const resultData = this.getBeforeOrAfterDataByMapContainerIdHandler(mapObject)
      const ele = document.createElement('div')
      ele.setAttribute('class', 'measure-line-result close')
      const left = window.document.documentElement.clientWidth > 7000 ? 20 : 8
      const top = window.document.documentElement.clientWidth > 7000 ? -50 : -11
      const option = {
        element: ele,
        anchor: 'bottom-left',
        offset: [left, top]
      }
      ele.innerHTML = '×'
      // eslint-disable-next-line no-undef
      new mapBoxGL.Marker(option)
          .setLngLat(coords)
          .addTo(resultData.map)
      ele.onclick = function (__e) {
        __e.stopPropagation()
        this.closeMeasureLine(resultData.map)
      }
    },
    closeMeasureLine(mapObject) {
      this.map.doubleClickZoom.enable();
      if (!mapObject) return
      const resultData = this.getBeforeOrAfterDataByMapContainerIdHandler(mapObject)
      if (!resultData) return
      this.clearMeasureLine(resultData.map)
      resultData.map.off('click', resultData.mapClickFunction)
      resultData.map.off('mousemove', resultData.mapMousemoveFunction)
      resultData.map.off('dblclick', resultData.mapDblClickFunction)
      resultData.map.off('click', 'measure-line-points', resultData.clickMeasurePointsFunction)
      resultData.isMeasure = false
      resultData.map.getCanvas().style.cursor = ''
      resultData.map.getSource('measure-line-move').setData({'type': 'FeatureCollection', 'features': []})
      resultData.jsonPoint = {'type': 'FeatureCollection', 'features': []}
      resultData.jsonLine = {'type': 'FeatureCollection', 'features': []}
      resultData.points = []
    },
    getBeforeOrAfterDataByMapContainerIdHandler(mapObject) {
      if (!mapObject) return
      const currentMapContainerId = mapObject._container.getAttribute('id')
      return currentMapContainerId.indexOf('-after') !== -1 ? this.after : this.before
    },
    updateArea() {
      const data = this.draw.getAll();
      if (data.features.length > 0) {
        const area = turf.area(data);
        const rounded_area = Math.round(area * 100) / 100;
        document.getElementById('Area').innerText = '总面积：' + rounded_area / 10000 + '万平方米'
      }
      return ''
    },
    newsIsOpen() {
      this.newIsShow = !this.newIsShow
    },
    getNewsData() {
      GetFirePointPaginated(
          {
        reliabilityList:this.value1.toString(),
        seekOutTimeStart: this.selectedTime ? this.selectedTime[0] : '',
        seekOutTimeEnd: this.selectedTime ? this.selectedTime[1] : '',
        areaId: this.areaId ? this.areaId : null,
          },10,this.currentPage,
      ).then(res => {
        const firePointData = res.data.records[0].features || []
        if (firePointData.length === 0) {
          this.isLoaded = true
        }
        if (!this.isLoaded) {
          this.newsData = this.newsData.concat(firePointData)
          this.currentPage++
          this.getNewsData()
        }
      })
    },
    getNewsDataAuto() {
      reQuest({
        url: '/blade-overview/selectFirePointByGeoJson',
        params: {
          sourceType: 8,
          reliabilityList: this.value1.toString(),
          size: 10,
          page: this.currentPage,
        }
      }).then(res => {
        const firePointData = res.data.records[0].features || []
        if (firePointData.length === 0) {
          this.isLoaded = true
        }
        if (!this.isLoaded) {
          this.newsData = this.newsData.concat(firePointData)
          this.currentPage++
          this.getNewsData()
        }
      });
    }
  },
  computed: {//计算属性
        cssVars() {
          if (!this.isGreenLayer) {
            return {
              "--color": "rgba(255, 255, 255, 0.08)"
            }
          }else {
            return {
              "--color":"rgba(0, 0, 0, 0.4)"
            }
          }
        }
  },
  watch: {//状态监听  元素中写入v-model="变量名"

  },
  filters: {//过滤器

  }
}
</script>
<style lang="scss" scoped>
@import "../assets/styles/mapbox-gl.css";
@import "../assets/styles/mapbox-gl-draw.css";

::v-deep {
  .el-loading-mask{
    z-index: 2011;
    background-color: rgba(200,200,200,.5);
  }
  .mapboxgl-control-container {
    position: absolute;
    bottom: 50px;
    right: 140px;
    display: block;
  }

  .mapboxgl-ctrl-group {
    display: none;
  }

  .el-select {
    position: absolute;
    top: 62px;
    left: 20px;
    width: 280px;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    border: 1px solid rgba(170, 202, 255, 0.48);
  }

  .el-range-input {
    background-color: transparent;
    cursor: pointer;
  }

  .el-input__inner {
    background-color: var(--color);
    border: 1px solid rgba(170, 202, 255, 0);
  }

  .el-tag {
    background-color: rgba(78, 81, 83, 1);
    border-radius: 3px 3px 3px 3px;
    border: rgba(78, 81, 83, 1);
    opacity: 1;
  }

  .el-tag--small {
    background-color: rgba(78, 81, 83, 1);
    border-radius: 3px 3px 3px 3px;
    border: rgba(78, 81, 83, 1);
  }

  .el-tag--light {
    background-color: rgba(78, 81, 83, 1);
    border-radius: 3px 3px 3px 3px;
    border: rgba(78, 81, 83, 1);
  }
}

#map {
  width: 100vw;
  height: calc(100vh - 48px);
  background-color: #202020;

  .dataTime {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 280px;
    height: 32px;
    background: var(--color);
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    border: 1px solid rgba(170, 202, 255, 0.48);
    color: white;
    z-index: 999;

    div {
      text-align: center;
      width: 260px;
      height: 20px;
      font-size: 14px;
      line-height: 32px;
      -webkit-background-clip: text;
    }
  }

  .timeSelect {
    position: absolute;
    left: 40%;
    bottom: 20px;
    width: 480px;
    height: 60px;
    background-color: var(--color);
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    border: 1px solid rgba(170, 202, 255, 0.48);
    z-index: 999;

    .timeSelectInput {
      margin-top: 8px;
      margin-left: 14px;
      width: 400px;
      height: 36px;
      background: #0C0E12;
      opacity: 1;
      border: 1px solid #464950;
    }

    .playButton {
      position: relative;
      top: -36px;
      left: 416px;
      width: 30px;
      height: 30px;
      cursor: pointer;
      color: white;
      font-size: 15px;
      line-height: 30px;
    }
  }
}

.firePointMenu {
  position: absolute;
  left: 194px;
  top: 30px;
  width: 160px;
  height: 68px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.38);
  -webkit-backdrop-filter: blur(8px);
  text-align: center;
  border: 0.5px solid rgba(170, 202, 255, 0.3);
  cursor: pointer;
  user-select: none;
  display: none;
}

.firePointMenu span {
  color: white;
  line-height: 68px;
  font-size: 18px;
}

.item_active {
  border: 0.5px solid rgba(170, 202, 255, 0.4);
  background-color: rgba(0, 38, 55, 1);

}

.tool_menu {
  position: absolute;
  top: 403px;
  right: 0;
}

.tool {
  margin-top: 10px;
  background-image: url("@/assets/img/tool.png");
  background-size: 100%;
  background-repeat: no-repeat;
  overflow: hidden;
  width: 172px;
  height: 56px;
  cursor: pointer;
  font-size: 18px;
  user-select: none;
  color: white;
}

.tool_active {
  background-image: url("@/assets/img/toolOn.png");
  color: rgba(146, 189, 255, 1);
}

.ranging {
  margin-left: 30px;
  margin-top: 16px;
  width: 24px;
  height: 24px;
  background-image: url("@/assets/img/ranging.png");
  background-size: 100%;
}

.measuredArea {
  margin-left: 30px;
  margin-top: 16px;
  width: 24px;
  height: 24px;
  background-image: url("@/assets/img/measuredArea.png");
  background-size: 100%;
}
.earth {
  margin-left: 30px;
  margin-top: 16px;
  width: 24px;
  height: 24px;
  background-image: url("@/assets/img/earth.png");
  background-size: 100%;
}

.tool_span {
  position: relative;
  left: 70px;
  top: -23px;
}

#Area {
  height: 75px;
  width: 150px;
  position: absolute;
  bottom: 40px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.58);
  -webkit-backdrop-filter: blur(8px);
  padding: 15px;
  text-align: center;
  color: white;
}
</style>
